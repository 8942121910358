/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowDown } from "react-icons/md";
import Input from "./inputOne";
import Idown from "../../assets/images/down-arrow.svg";

const Dropdown = styled.div`
		top: calc(100% + 5px);
		background: var(--C_gray_lite);
		z-index: 999999;
  left: 0;
		
		& li {
			border-bottom: 1px solid #aaa;
			transition: all 0.3s ease-in-out;
		}
		& li:hover {
			background: var(--C_white);
			color: var(--C_black);
			transition: all 0.3s ease-in-out;
		}
	`,
	Container = styled.div``,
	Search = styled.input`
		border-radius: 0px;
	`;

const SelectTwo = ({
	value,
	name,
	placeholder,
	label,
	options,
	onChange,
 required,
	disabled,
 inputClass,
	wrapperClass,
	labelClass,
 useSearch=false,
}) => {
 const [show, setShow] = useState(false);
	const searchInput = useRef(null);

	return (
		<Input
			value={value}
			name={name}
			label={label}
			placeholder={placeholder}
			labelClass={labelClass}
   disabled={disabled}
			wrapperClass={`relative w-[100%] ${wrapperClass}`}
			inputClass={`cursor-pointer ${inputClass}`}
			inputC={"cursor-pointer"}
			icon={Idown}
            iconClass={"w-[18px] h-[18px] mt-2"}
			required={required}
			onClick={(e) => {
				e.target.blur();
				setShow(!show);
				setTimeout((_) => {
					searchInput?.current?.focus();
				}, 1000);
			}}>
    <div>
			{show && (
				<Dropdown className=" h-[180px] absolute mb-5 w-[94%] shadow-lg rounded">
					<div className={`${useSearch ?"h-[140px]":"h-[180px]"} pb-2`}>
						{useSearch&&<Search
							ref={searchInput}
							className="rounded-none outline-none w-full px-3 py-2 italic shadow-none"
							placeholder="Search here..."
						/>}
						<Container className="scroll h-full overflow-auto pt-3">
							{options.map((name, i) => {
								return (
									<li
										key={i}
										onClick={(e) => {
											if(onChange) onChange(name);
											setShow(false);
										}}
										className="mb- list-none capitalize py-2 px-4 cursor-pointer">
										{name}
									</li>
								);
							})}
						</Container>
					</div>
				</Dropdown>
			)}
   </div>
		</Input>
	);
};

export default SelectTwo;
