/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Modal from "../../layout/Modal";
import Input from "../form/inputOne";
import Button from "../form/button/Button";
import "./ModalOne.css";
import { FaTimes } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";

const List = styled.div`
	display: inline-block;
	background: #333;
	padding: 0.5rem;
	color: var(--C_white);
	border-radius: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
`;

export default function RolesModal({
	showModal,
	setModal,
	data,
	permissions,
	setPermissions,
	data_,
	setData,
	payload,
	setPayload,
	disabled,
	getRoles,
}) {
	const [search, setSearch] = useState([]);
	const searchInput = useRef(null);

useEffect(() => setSearch(permissions), [showModal]);

	const searchPermissions = (e) => {
		let value = e.target.value,
		data;
		if (value) {
				data = permissions.filter((item) => item.toLowerCase().indexOf(value.toLowerCase()) > -1)
		} else data = permissions;
		setSearch(data)
	};

	useEffect(() => {
		setPayload({ ...payload, permissions: data_ });
	}, [data_]);

	const handleRemove = (e) => {
		e.stopPropagation();
		let value;
		if (e.target.tagName === "svg") {
			value = e.target.parentElement.firstElementChild.innerText;
		} else
			value = e.target.parentElement.parentElement.firstElementChild.innerText;
		setSearch([...search, value]);
		setPermissions([...permissions, value]);
		setData(data_.filter((item) => item !== value));
	};

	const handleAdd = (e) => {
		e.stopPropagation();
		let value = e.target.innerText;
		
		setSearch(search.filter((item) => item !== value));
		setPermissions(permissions.filter((item) => item !== value));
		setData([...data_, value]);
	};

	const handleClose = () => {
		if (data_.length) setData([]);
		
	};

	return (
		<Modal
			{...{
				showModal,
				setModal,
				// top: "1em",
				width: "700px",
				height: "600px",
			}}>
			<form
				className="mt-5 mx-10"
				onSubmit={(e) => {
					e.preventDefault();
					data.action(data?.id, payload, getRoles);
				}}>
				<div className="my-5 flex justify-between items-center">
					<h1 className="py-0 mt-3 text-2xl text-center leading-7">
						{data.title}
					</h1>

					<Button
						onClick={(_) => {
							handleClose();
							setModal(!showModal);
						}}
						variant={"secondary"}
						value={
							<GrClose
								size={"20px"}
								className="font-bold  mx-[5px]  py-0 text-white "
							/>
						}
						wrapperClass={"px-2 mt-3"}
					/>
				</div>

				<Input
					name={"name"}
					value={payload.name}
					label={"Name"}
					placeholder={"Enter name here"}
					required={true}
					wrapperClass={"mt-10 mb-3 w-full"}
					inputClass={"w-full"}
					onChange={(e) => setPayload({ ...payload, name: e.target.value })}
				/>

				<div>
					<label htmlFor="permissions" className="mb-20 text-start block">
						Permissions
					</label>
					<label
						className="rounded pt-2"
						style={{
							outline: "none",
							width: "100%",
							background: "#e9edff",
							display: "block",
						}}>
						<div className="px-2 pt-2">
							{data_.map((d, i) => {
								return (
									<List
										className="inline-block shadow"
										key={i}
										style={{ background: "#fff", color: "#333" }}>
										<div className="flex items-center">
											<span className="mr-2">{d}</span>
											<FaTimes
												className="cursor-pointer"
												color="var(--C_danger)"
												onClick={handleRemove}
											/>
										</div>
									</List>
								);
							})}
						</div>
						<input
							className="w-full py-3 "
							style={{ outline: "none", background: "#e9edff" }}
							name={"permissions"}
							type={"text"}
							ref={searchInput}
							onChange={searchPermissions}
							placeholder={"Search by permission..."}
						/>
					</label>
					<div
						className="dropdown cursor-pointer pt-3"
						style={{ background: "#F8FAFB" }}>
						{search.sort().map((permission, index) => (
							<List key={index} onClick={handleAdd} className="px-2 shadow">
								{permission}
							</List>
						))}
					</div>
				</div>

				<Button
					wrapperClass={"mt-10 mb-5"}
					value={disabled ? "Saving..." : data.btnText}
					width={"100%"}
					type={"submit"}
					disabled={disabled}
				/>
			</form>
		</Modal>
	);
}
