/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import Table from "../../components/table/Table";
import UserAction from "../../actions/user";
import HomeLayout from "../../layout/Home";
import CardRequest from "../../components/modal/CardRequest";
import dayjs from "dayjs";
import Select from "../../components/form/Select";
import RejectCard from "../../components/modal/RejectCardRequest";

const PendingCardRequest = () => {

	const [showModal, setModal] = useState({cardRequest: false, reject: false});
	const [users, setUsers] = useState([]);
	const [error, setError] = useState("Loading card requests...");
	const [isLoading, setIsLoading] = React.useState(false);
	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		length: 0,
		search: '',
		status: "pending",
	}),
 [modalData, setModalData] = React.useState({
		actionName: "createUser",
		reject: false,
	});

	const getUsers = async () => {
		try {
			const users = await UserAction.getCardRequest(pagination);
			function loadUsers({ data }) {
				setIsLoading(false);
				setPagination((state) => {
					return {
						...state,
						total: data?.count,
						length: pagination.pageSize * pagination.page,
					};
				});

				const userData = data?.cardRequests?.map((res) => {
					const {
						id,
					} = res;
					return {
						_id: id,
						// "account no.": res?.accountNumber || 'Nil',
						"BVN": res?.bvn || 'Nil',
						"account name": res?.accountName || "Nil",
						"account type": res?.accountType || "Nil",
						"card type": res?.cardType || "Nil",
						"Updated Time & Date": dayjs(res?.updatedAt).format('DD-MM-YYYY HH:ss'),
						_data: res,
					};
				});

				userData.length === 0
					? setError(`No ${pagination.status} card request found`)
					: userData === (null || undefined)
					? setError("Error loading card request")
					: setUsers(userData);
				return userData;
			}
			loadUsers(users);
		} catch (error) {
			setError("Error loading card request");
		}
	}


	useEffect(() => {
	
		let cb = () => {};
		 if(pagination.search) {
				cb = setTimeout(async ()=>{
					setIsLoading(true);
						await getUsers();
				}, 700)
			} else {
				setIsLoading(true);
				(async () => {
					await getUsers();
				})();
			}

			return () => {
				clearTimeout(cb)
			}
	}, [pagination.page, pagination.status, pagination.search]);

	const handleSearch = async (e) => {
		const { value } = e.target;

		setPagination((state) => {
			return { ...state, search: value, page: 1 };
		});
	};



	const dropdownMenu = [
		{
			text: "View Card Request",
			action: (e, data) => {
				setModalData({
					id: data?._data?.id,
					data: data._data,
				});
				setModal(state =>({reject: false, cardRequest: true}));
			},
		}
	],
	handleFilter = (e) => {
		setPagination((state) => {
			return { ...state, page: 1, status: e.target.value };
		})
	}

	return (
		<HomeLayout {...{ useSearch: true, showNavigation: true, handleSearch }}>
			<div className="mt-[10%]">
				<div className="flex justify-between">
					<h1 className="font-[500] mb-14 text-[24px] text-[#263238]">
						Card Request
					</h1>
					<div className="flex">
						<div
							className="flex items-center new_user__btn_di h-[45px] cursor-pointer rounded-md"
							>
								<p className="mr-5">Sort By</p>
								<Select
						name={"status"}
      height="40px"
						selectClass={"py-2 bg-white shadow block rounded-lg"}
						onChange={handleFilter}
						options={[{name: 'Pending', value: 'pending'}, {name: 'Approved', value: 'approved'}, {name: 'Rejected', value: 'rejected'}]}
					/>

						</div>
					</div>
				</div>

				<section className="w-full pb-5">
					<div className="table-one rounded-t-[10px]">
						<div className=" h-[50px]  bg-[#F9F9F9] text-start pt-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] capitalize">{pagination.status}</h1>
						</div>
						{users.length ? (
							<Table
								{...{
									dropdownMenu,
									getUsers,
									setError,
									data: users,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</section>
			</div>

			{/* {showModal.cardRequest && ( */}
				<CardRequest
					getUsers={getUsers}
					showModal={showModal.cardRequest}
					setModal={val => setModal(state=>({...state, cardRequest: val}))}
					data={modalData}
				/>
			{/* )} */}

{/* {showModal.reject && ( */}
				<RejectCard
					getUsers={getUsers}
					showModal={showModal.reject}
					setModal={val => setModal(state=>({...state, reject: val}))}
					data={modalData}
				/>
			{/* )} */}
		</HomeLayout>

 );
}

export default PendingCardRequest;
