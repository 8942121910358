import React from "react";
import Logo from "../../assets/images/logo.png";
import Leo from "../../assets/images/leo.svg";


const CustomLayout = ({ children, component, onSubmit, errorMsg }) => {
    return (

        <div className="App flex justify-between h-screen">
        <div className="login-container relative">
            <div className="w-[144px] h-[67px] login-img">
            <a href='/'> <img className=" 0" src={Logo} alt="logo" /> </a>
            </div>
            <div className="w-[417px] h-[308] login-img2 absolute bottom-0">
            <img className=" 0" src={Leo} alt="logo" />
            </div>
        </div>
        <div className="m-auto h-screen bg-[#F8F1F1] w-[66%]  login-wrapper rounded-l-3xl">
            <div className=" rounded-lg login-wrapper2 m-auto">

                {/* {errorMsg && (
                    <div className="flex justify-center">
                        <div className="w-full mt-8">
                            <p className="text-[#525252] font-[500] text-[25px] text-start mb-8">{errorMsg || 'The email address is invalid'}</p>
                        </div>
                    </div>
                )} */}
                <div className="flex justify-center items-center">
            <form
                className="login-form h-screen flex justify-center items-center"
                onSubmit={onSubmit}
            >
                {component||children}
            </form>
            </div>
            </div>
        </div>
        </div>
    );
    }

    export default CustomLayout;