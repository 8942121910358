/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import "./User.css";
import IPlus from "../../assets/images/plus2.svg";
import Table from "../../components/table/Table";
import Modal from "../../components/modal/ModalOne";
import RoleAction from "../../actions/roles";
import UserAction from "../../actions/user";
import { FiDownloadCloud, FiUploadCloud } from "react-icons/fi";
import UploadUsers from "../../components/modal/UploadUsers";
import HomeLayout from "../../layout/Home";
import Delete from "../../components/modal/Delete";
import DatePicker from "../../components/form/DatePicker/DatePicker";
import config from "../../utils/config";
import UnlockUser from "../../components/modal/Unlock";
import ResetUsers from "../../components/modal/resetUsers";

const User = () => {
  const [showModal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("Loading users...");
  const [modals, setModals] = useState({ upload: false });
  const [isLoading, setIsLoading] = React.useState(false);
  const [deleteUser, setDeleteUser] = useState({ state: false, data: {} });
  const [unlock, setUnlock] = useState({ state: false, data: {} });
  const [reset, setReset] = useState({ state: false, data: {} });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    role: "",
    date: new Date(),
    search: "",
  });

  const dlUrl = () => {
    let url = config.backendUrl.endsWith("/")
      ? config.backendUrl + "auth/downloadinactiveusers"
      : config.backendUrl + "/auth/downloadinactiveusers";

    return `${url}/?date=${pagination.date}`;
  };
  const [modalData, setModalData] = React.useState({
    actionName: "createUser",
  });

  const getUsers = async () => {
    try {
      const users = await UserAction.getUsers(pagination);
      function loadUsers({ data }) {
        setIsLoading(false);
        setPagination((state) => {
          return {
            ...state,
            total: data?.count,
            length: pagination.pageSize * pagination.page,
          };
        });

        const userData = data?.users.map((res) => {
          const {
            firstName,
            email,
            lastName,
            branch,
            role,
            staffId,
            // virtualAccount,
            locked,
            lastloggedin,
            status,
            id,
          } = res;
          return {
            _id: id,
            name: firstName + " " + lastName,
            branch: branch || "Nil",
            role,
            "staff ID": staffId,
            email,
            "Last LoggedIn": `${
              lastloggedin ? new Date(lastloggedin).toDateString() : "Nil"
            }`,
            Status: locked ? "Locked" : status,
            _data: res,
          };
        });

        userData.length === 0
          ? setError("No users found")
          : userData === (null || undefined)
          ? setError("Error loading users")
          : setUsers(userData);
        return userData;
      }
      loadUsers(users);
    } catch (error) {
      setError("Error loading users");
    }
  };

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      cb = setTimeout(async () => {
        setIsLoading(true);
        await getUsers();
      }, 700);
    } else {
      setIsLoading(true);
      (async () => {
        await getUsers();
      })();
    }

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.page, pagination.date, pagination.search]);

  const [roles, setRoles] = useState([]);
  useMemo(async () => {
    const res = await RoleAction.getRoles(pagination);
    if (res?.success) setRoles(res?.data?.roles?.map(({ name }) => name));
  }, []);

  const handleSearch = async (e) => {
    const { value } = e.target;

    setPagination((state) => {
      return { ...state, search: value };
    });
  };

  const onChange = async (date) => {
    setPagination((state) => {
      return {
        ...state,
        date,
        page: 1,
      };
    });
  };

  //delete user by uuid and refresh the page
  const handleDelete = async (uuid, cb) => {
    try {
      let res = await UserAction.deleteUser(uuid);
      cb();
      if (res.success) {
        setPagination((state) => ({ ...state, search: "" }));
        getUsers();
        setDeleteUser((state) => ({ ...state, state: false }));
      }
    } catch (error) {
      setError(error.response.data.message);
      setIsLoading(false);
    }
  };

  //unlock user by email and refresh the page
  const handleUnlock = async (email, cb, locked) => {
    try {
      const actionName = locked ? "unlockUser" : "lockUsers";
      let res = await UserAction[actionName](email);
      cb();
      if (res.success) {
        setPagination((state) => ({ ...state, search: "" }));
        getUsers();
        setUnlock((state) => ({ ...state, state: false }));
      }
    } catch (error) {
      setError(error.response.data.message);
      setIsLoading(false);
    }
  };

  //unlock user by email and refresh the page
  const handleReset = async (email, cb) => {
    try {
      let res = await UserAction.resetUsers([email]);
      cb();
      if (res.success) {
        setPagination((state) => ({ ...state, search: "" }));
        getUsers();
        setReset((state) => ({ ...state, state: false }));
      }
    } catch (error) {
      setError(error.response.data.message);
      setIsLoading(false);
    }
  };

  //update user by uuid and refresh the page
  const handleEdit = async (userId) => {
    try {
      await UserAction.updateUser(userId);
      setIsLoading(true);
      setTimeout((state) => 3000);
      getUsers();
    } catch (error) {
      setError(error.response.data.message);
      setIsLoading(false);
    }
  };

  const dropdownMenu = [
    {
      text: "Edit",
      action: (e, data) => {
        setModalData({
          actionName: "updateUser",
          id: data?._data?.id,
          data: data._data,
        });
        setModal(true);
      },
    },
    {
      text: "Delete",
      action: (e, prop) => {
        setDeleteUser({
          state: true,
          data: {
            action: (cb) => handleDelete(prop._data.id, cb),
            name: prop._data.firstName + " " + prop._data.lastName,
          },
        });
      },
    },
    {
      text: "Unlock",
      condition: ({ _data }) => _data.locked,
      action: (e, prop) => {
        setUnlock({
          state: true,
          data: {
            locked: prop._data.locked,
            action: (cb) =>
              handleUnlock(prop._data.email, cb, prop._data.locked),
            name: prop._data.firstName + " " + prop._data.lastName,
          },
        });
      },
    },
    {
      text: "Lock",
      condition: ({ _data }) => !_data.locked,
      action: (e, prop) => {
        setUnlock({
          state: true,
          data: {
            locked: prop._data.locked,
            action: (cb) =>
              handleUnlock(prop._data.email, cb, prop._data.locked),
            name: prop._data.firstName + " " + prop._data.lastName,
          },
        });
      },
    },
    {
      text: "Reset password",
      action: (e, prop) => {
        setReset({
          state: true,
          data: {
            action: (cb) => handleReset(prop._data.email, cb),
            name: prop._data?.firstName + " " + prop._data?.lastName,
          },
        });
      },
    },
  ];

  return (
    <HomeLayout {...{ useSearch: true, showNavigation: true, handleSearch }}>
      <div className="mt-[10%]">
        <div className="flex justify-between">
          <h1 className="font-[500] mb-5 text-[24px] text-[#263238]">
            User Management
          </h1>
        </div>
        <div className="flex justify-between items-center mb-8">
          <div>
            <DatePicker
              className="px-3 py-2  text-center shadow rounded-lg"
              selected={pagination.date}
              onChange={onChange}
              isClearable={false}
            />
          </div>
          <div className="flex">
            <a
              className="flex items-center mr-5 h-[45px] px-3 cursor-pointer bg-[#000] text-white rounded-md decoration-none"
              href={dlUrl()}
            >
              <FiDownloadCloud size={20} className="mr-2" />
              <span>Download Users</span>
            </a>

            <div
              className="flex new_user__btn_div h-[45px] px-3 mr-5 cursor-pointer"
              onClick={(e) => setModal((_) => !showModal)}
            >
              <img src={IPlus} alt="" className="w-5 mr-2" />
              <button
                className="text-[#FF0000]"
                onClick={(_) => {
                  setModalData({
                    actionName: "createUser",
                    data: null,
                  });
                }}
              >
                Add new user
              </button>
            </div>

            <div
              className="flex items-center new_user__btn_di h-[45px] px-3 cursor-pointer bg-[#FF0000] rounded-md"
              onClick={(e) =>
                setModals((state) => ({ ...state, upload: true }))
              }
            >
              <FiUploadCloud size={20} className="text-white" />
              <button className="text-white ml-3">Upload users</button>
            </div>
          </div>
        </div>

        <section className="w-full pb-5">
          <div className="table-one rounded-t-[10px]">
            <div className=" h-[50px]  bg-[#F9F9F9] text-start pt-4 px-7 ">
              <h1 className=" font-[400] text-[14px] text-[#010918] ">Users</h1>
            </div>
            {users.length ? (
              <Table
                {...{
                  dropdownMenu,
                  handleEdit,
                  getUsers,
                  setError,
                  data: users,
                  handleDelete,
                  showModal,
                  setModal,
                  isLoading,
                  pagination,
                  setPagination,
                }}
              />
            ) : (
              <h1 style={{ color: "var(--C_gray)" }} className="text-center">
                {error}
              </h1>
            )}
          </div>
        </section>
      </div>

      {showModal && (
        <Modal
          getUsers={getUsers}
          showModal={showModal}
          setModal={setModal}
          options={roles}
          data={modalData}
        />
      )}
      <UploadUsers
        {...{
          getUsers,
          showModal: modals.upload,
          setModal: (val) => setModals((state) => ({ ...state, upload: val })),
        }}
      />

      <Delete
        {...{
          setDeleteModal: (val) =>
            setDeleteUser((state) => ({ ...state, state: val })),
          showDeleteModal: deleteUser.state,
          data: deleteUser.data,
        }}
      />

      <UnlockUser
        {...{
          setOpen: (val) => setUnlock((state) => ({ ...state, state: val })),
          open: unlock.state,
          data: unlock.data,
        }}
      />
      <ResetUsers
        {...{
          setOpen: (val) => setReset((state) => ({ ...state, state: val })),
          open: reset.state,
          data: reset.data,
        }}
      />
    </HomeLayout>
  );
};

export default User;
