/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
// import { getRole, Roles, Statuses } from "../../../utils/helpers";
import styled from "styled-components";
import HomeLayout from "../../layout/Home";
import Avatar from "../../assets/images/avatar.png";
import JsPDF from 'jspdf';
import { LazyLoadImage } from "react-lazy-load-image-component";


const Info = styled.p`
	border-bottom: 1px solid black;
	padding-bottom: 5px;
	background-color: transparent;
	width: 250px;
	text-align: center;
`;
// const { REACT_APP_PUBLIC_URL } = process.env;
const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL ?? 'https://uinstaprodapi.ubagroup.com';

export default function Profile1() {
	const { state } = useLocation();

	const generatePDF = () => {

		const report = new JsPDF('landscape','pt');
		report.html(document.querySelector('#report')).then(() => {
			report.save('uinsta_one_report.pdf');
		});
	}
	
	return (
		<HomeLayout {...{ showNavigation: true }}>
			<button className="mt-10 bg-gray-300 rounded p-1 cursor-pointer hover:bg-white" onClick={generatePDF} type="button">Download as PDF</button>
			<div id="report" className="mt-[1%] border-2 px-5 rounded shadow my-5">
				<h2 className="mt-10"><b><span className="mr-2">UNITED</span><span className="mr-2">BANK</span><span className="mr-2">OF</span><span className="mr-2">AFRICA</span><span>PLC</span></b></h2>
				<span className="mr-2">CUSTOMER</span> <span>INFORMATION</span>
				<form className="w-full md:w-4/5 md:mx-right-auto my-10">
					<div className="flex mt-5 w-full justify-between items-center">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								First Name
							</p>
							<Info>{state?.firstName}</Info>
						</div>

						<div className="mr-5">
							<p
								className="text-bold mb-1"
								style={{ color: "var(--C_black)" }}>
								Middle Name
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{" "}
								{state?.middleName}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Last Name
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.lastName}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Email Address
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.email || "Nil"}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Phone Number
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.phoneNumber}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								State Of Origin
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.stateOfOrigin || "Nil"}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Scheme Description
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.schemeDescription}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								BVN
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.bvn}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Account Number
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.accountNumber || "Nil"}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Date Of Birth
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.dob}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Gender
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.gender}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Branch Address
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{state?.residentialAddress || "Nil"}{" "}
							</Info>
						</div>
					</div>
				</form>

				<div className="flex mt-20 ml-10">
						<div className="mx-5">
						<p className="text-left ml-1 mb-2">Passport</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`data:image/png;base64,${state?.passport}`} width="150px" height="150px" alt="Passport"/>
						</div>
						<div className="mx-5">
						<p className="text-left ml-1 mb-2">Signature</p>
						<LazyLoadImage placeholderSrc={Avatar} src={state?.customerSignature} width="150px" height="150px" alt="Signature"/>
						
						</div>

				</div>
				<div className="fle">
    <h2 className="text-[23px] text-center">Creator Information</h2>
				</div>
				<div className="flex mx-auto w-full md:mx-right-aut my-10">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Email
							</p>
							<Info>{state?.user?.email}</Info>
						</div>

						<div>
							<p
								className="text-bold mb-1"
								style={{ color: "var(--C_black)" }}>
								Branch
							</p>
							<Info>
								{" "}
								{state?.user?.branch}{" "}
							</Info>
						</div>
            </div>
			
			</div>
		</HomeLayout>
	);
}
