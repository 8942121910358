import React from "react";
import IarrowgreenUp from "../../../assets/images/arrowgreenUp.svg";
import Iclock3 from "../../../assets/images/clock3.svg";
import { Link } from "react-router-dom";

const FailedItems = () => {
  return (
    <div className="px-4 mb-5 mt-10  py-5 ml-5 rounded-[5px]  -[235px]  bg-[#F9F9F9]">
      <div className="flex justify-center">
      <div className="text-start  w-[173px]">
        <p className="text-[#010918] text-[12px] font-[300] pl-3  my-2">This Week</p>
        <p className="text-[#7995AC] text-[12px] font-[400] pl-3 ">
          Failed Accounts
        </p>
        <div className="flex justify-center">
          <p className="text-[#010918]  text-[55px] font-[700]  pl-20 ">0</p>
          <img src={IarrowgreenUp} alt="money" className="ml-1" />
        </div>
        
      </div>
      <div className=" w-[50px] h-[50px] bg-[#FF0B00] rounded-[5px] mr-5 mt-2">
      <img src={Iclock3} alt="money" className="w-[22px] mx-4 my-3 h-[22px] " />
      </div>
      </div>
      <Link to="/accounts/failed" >
        <p className="text-[#010918] text-[14px] font-[400] pl-3">
        Failed Accounts Opening Requests
        </p>
        </Link>
    </div>
  );
};

export default FailedItems;
