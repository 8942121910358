import API from './axios'
import Alert from "./alert"

class BACKEND {
  constructor() {
    this._API = API;
  }
   send({type, to, payload, cb, useAlert=true}) {
    
    return this._API[type](to, payload).then(function (response) {
      if ([200, 201].includes(response.status)) {
        if (useAlert) {
        Alert({
          type: 'success',
          message: response.data.message,
          cb: _ => cb ? cb(response.data) : ''
        })
      }
        return response.data
      } else {
        Alert({
          type: 'error',
          message: response.data.message
        })
        return response.data
      }
    }).catch(function (e) {
      console.error(e ?? 'error')
       const err = "error"
      const message = e?.message || e[err] || "Something went wrong";
      if (useAlert) {
      Alert({
        type: err,
        message
      })
    }
      return message
    })
  }

  login(payload) {
    Alert({
      type: "info",
      message: "Signing in...",
      timer: 5000
    })
    return this.send({
      to: '/auth/login',
      type: "post",
      payload
    })
  }

  async validateToken(payload, cb) {
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000
    })

    const response = await this.send({
      to: '/auth/verifyauth',
      type: "post",
      payload,
      cb
    })
    return response;
  }

  async createUser(payload, cb) {
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000
    })

    const response = await this.send({
      to: '/auth/createuser',
      type: "post",
      payload,
      cb
    })

    return response;
  }
  
  // async updateUser(payload) {
  //   Alert({
  //     type: "info",
  //     message: "Processing request...",
  //         timer: 5000
  //     })

  //     const response = await this.send({
  //       to: '/auth/updateuser',
  //       type: "patch",
  //       payload
  //     })

  //     return response;
  //     }
      
      
  async forgotPassword (payload, cb){
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000
    })
    const response = await this.send({
      to: '/auth/forgotpassword',
      type: "post",
      payload, cb
    })
    return response
  }

  async resetPassword (payload, cb){
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000
    })
    const response = await this.send({
      to: '/auth/resetpassword',
      type: "post",
      payload, cb
    })
    return response
  }

  async changePassword (payload, cb) {
    
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000
    })
    const response = await this.send({
      to: '/auth/changepassword',
      type: "patch",
      payload, cb
    })
    return response
  }

  isAuthenticated() {
    return this._API.get('/user').then(function (response) {
      if (response?.status === 200) {
        return response.data
      } else {
        return false
      }
    }).catch(function (error) {
      console.log(error)
      return false
    })
  }


  getUser() {

    return this._API.get('/user').then(function (response) {
      if (response.status === 200) {
        return response.data
      } else {
        return false
      }
    }).catch(function (error) {
      return false
    })
  }

  getUsers(pagination) {

    return this._API.get(`/auth/users/?page=${pagination.page}&pageSize=${pagination.pageSize}`).then(function (response) {
      if (response.status === 200) {
        return response.data
      } else {
        return response.data
      }
    }).catch(function (error) {

      return false
    })
  }

  getAccounts(payload) {
    return this._API.get('/accounts/getaccounts', payload).then(function (response) {
      if (response.status === 200) {
        return response.data
      } else {
        return response.data
      }
    }).catch(function (error) {
      return false
    })
  }

  updateUser(payload, id, cb) {
    return this.send({
      to: `/auth/user/${id}`,
      type: "patch",
      payload, cb
    })
  }

  deleteUser(id, cb) {
    return this.send({
      to: `/auth/user/${id}`,
      type: "delete",
      cb
    })
  }

}

export default BACKEND;