/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react'
import UserAction from '../../../actions/user';
import Ipie from "../../../assets/images/pie.svg";

const TotalRegistered = ({totalAccounts}) => {

  return (
    <div>
         <div className=" wraper-dash flex pl-6 pt-4 mt-10 h-[200px]  text-start  rounded-[5px]  bg-[#F7F9FB]">
                  <div className="pb-5 ">
                    <p className="text-[#7995AC] text-[14px] font-[400] pl-3 py-4 ">
                        Accounts
                    </p>
                    <p className="text-[#010918] text-[24px] font-[500] pl-3 pb-4">
                        {totalAccounts}
                    </p>
                    <p className="text-[#010918] text-[14px] font-[400] pl-3 pt-8">
                      Total Accounts Registered
                    </p>
                    
                  </div>
                  <div className=" ">
                    <img
                      className="w-[120px] h-[120px]  mt-4  "
                      src={Ipie}
                      alt="money"
                    />
                  </div>
                </div>
    </div>
  )
}

export default TotalRegistered