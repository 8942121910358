import React from 'react'
import UserAction from "../../actions/user";
import Ibackdrop from "../../assets/images/logo.png";
import Ilogout from "../../assets/images/power.svg";
import "./Dropdown.css";

const Dropdown = ({user, show}) => {
    const logout = () => {
        UserAction.logout();
      };

  return (
    <div>
        {show&&<div className="absolute custom-position shadow-lg right-2 bg-white py-3 w-[250px] vh-[80px] rounded">
				<div
					className="h-full w-full flex justify-center backdrop-img ">
					<div className="">
						<div className=" flex  w-full justify-between items-center mb-1">
							<img className="w-[45px]" src={Ibackdrop} alt="logo" />
							<h1 className="text-[11px] ml-1 font-[300]" style={{lineHeight:"10.05px"}}>Your UBA Account</h1>
						</div>
						<hr color="black" /> 

						<div className="mt-4">
							<span className="text-[11px]">You're signed in as</span>{" "}
							<span className="capitalize text-[12px]" style={{ color: "var(--C_primary)" }}>
								{user?.firstName+" "+user?.lastName}
							</span>
							<p className="text-slate-500 text-start">
								<span className="text-[16px]">Role</span>:{" "}
								<span className="capitalize text-[12px]">{user?.role}</span>
							</p>
						</div>
						<hr />
						<div className="mt-2 ">
							<p
								onClick={logout}
								className=" flex items-center justify-start cursor-pointer">
								<img src={Ilogout} alt="logout" className="cursor-pointer mr-1 w-[16px]" />{" "}
								<span className="text-[13px]">Logout</span>
							</p>
						</div>
					</div>
				</div>
			</div>}
    </div>
  )
}

export default Dropdown