/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Modal from "../../layout/Modal";
import "./ModalOne.css";
import BACKEND from "../../utils/backend";
import Imask from "../../assets/images/mask.svg";
import SelectField from "../form/SelectField";
import UserActions from "../../actions/user";
import Button from "../form/button/Button";

export default function CreateBranches
({ showModal, setModal, getBranches, data }) {
	const model = {
		solId: "",
		solDesc: "",
	};
	const [loading, setLoading] = useState(false),
	 [nameError, setNameError] = useState("")



	const [formData, setFormData] = useState(model);


	const setState = (setFormData, updatedData) => {
		setFormData((data) => {
			return { ...data, ...updatedData };
		});
	};

	const handleNameError = (e) => {
		const value = e.target.value.trim(),
			names = value.split(" ");
		if (value === "") {
			setNameError((_) => "Branch Name is required!");
	}
		else if (names.length < 2) {
			setNameError((_) => "You must enter branch name!");
		} else if (names.length >= 2) {
			setState(setFormData, {
				...formData,
				accountOfficerName: value,
			});
		}
	};

	function addData(e) {
		let data = {},
			key = e.target.getAttribute("name");
		if (key in formData) {
			data[key] = e.target.value;
			setState(setFormData, data);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true)
		UserActions[data.actionName](formData, data?.id)
			.then(async (res) => {
				if(res.success){

					// clears	the form
					e.target.reset()
					setState(setFormData, model);
					// closes the modal
					setModal(false);
					// refreshes the users table
					await getBranches();
				}
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
			});
	};

	const handleClose = () => {
		setModal(false);
	};


	return (
		<Modal {...{ showModal, setModal, width: "600px", height: "380px" }}>
				<form
					className="modal__one text-left"
					onSubmit={handleSubmit}>
					<div>
						<div className="modal__one__header flex justify-between">
							<h1 style={{fontSize: "18px", fontWeight: 'bolder'}} className="text-start  py-0 mt-4 ml-5 font-bold text-[#000000]">
								{data?.id ? "Update Branch" : "Add Branch"}
							</h1>
							<img
								src={Imask}
								alt=""
								className="py-0 mt-5 mr-5 cursor-pointer"
								onClick={handleClose}
							/>
						</div>
						<hr className="border-t-1 border-[#E4E4EB] w-[525px] my-5 m-auto" />
					</div>

							<div className="my-3">
							<label htmlFor="solId" style={{color: "grey"}}>SOL ID</label>
					<input
						type="text"
						name="solId"
						style={{fontSize: '18px'}}
						id="solId"
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						required
						value={formData.solId}
					/>
							</div>
			
			<div className="my-3">
				<label htmlFor="solDesc" style={{color: "grey"}}>BRANCH ADDRESS</label>
				<input
						type="text"
						name="solDesc"
						id="solDesc"
						style={{fontSize: '18px'}}
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						value={formData.solDesc}
						required
					/>
					<p style={{color: 'red'}}> {nameError} </p>
			</div>
			
					<div className="flex justify-end mt-5 mr-4 mb-8">
            <Button disabled={loading || nameError} {...{ 	width: "160px", value: loading? "Processing..." : data?.id ? "Update Branch" :  "Add Branch" , wrapperClass: "py-0", type: "submit" }} />
					</div>
				</form>
			
		</Modal>
	);
}
