/* eslint-disable no-unused-vars */
import Alert from './alert'
class Storage {

  static storage = window.localStorage;
  static userKey = "user";

  static get(key) {
    return this.storage.getItem(key);
  }

  static set(key, value) {
    if (value instanceof Object) value = JSON.stringify(value);
    this.storage.setItem(key, value);
    return true;
  }

  static remove(key) {
    this.storage.removeItem(key);
    return true;
  }

  static getUser() {
    let user = this.get(this.userKey)
    if (user) {
      user = JSON.parse(user);
    } else user = {}
    return user
  }
}

export default Storage