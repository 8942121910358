const environment = {};

const pageUrls = {
  login: "/",
  dashboard: "/dashboard",
  roles: "/roles",
  users: "/users",
  settings: "/settings",
  accounts: "/accounts",
  accountsv1: "/accounts/1.0",
  wallet: "/wallet",
  audit: "/audit",
};

environment.development = {
  backendUrl: process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://uinstaapi.timeguard.ng/',
 frontendHost: "http://localhost:3000/",
 pageUrls,
 encryptionKey: 'challengemeoften2023',
}

environment.staging = {
  backendUrl: process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://uinstaapi.timeguard.ng/',
 frontendHost: "http://localhost:3000/",
 pageUrls,
 encryptionKey: 'challengemeoften2023',
}

environment.production = {
 backendUrl: process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://uinstaapi.timeguard.ng/',
 frontendHost: "http://localhost:3000/",
 pageUrls,
 encryptionKey: process.env.REACT_APP_ENCRYPTION_KEY || 'challengemeoften2023',
}
console.log(process.env.REACT_APP_NODE_ENV, process.env.REACT_APP_BACKEND_URL);
export default environment[
  process.env.REACT_APP_NODE_ENV
    ? process.env.REACT_APP_NODE_ENV
    : "development"
];
