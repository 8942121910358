/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from './config'
import History from './history';
import Storage from './storage'
import Alert from './alert';

const API = axios.create({
 baseURL: config.backendUrl,
 timeout: 100000
});


API.defaults.headers.common['Authorization'] = `Bearer ${Storage.get('authToken')}`;
API.defaults.headers.common['ngrok-skip-browser-warning'] = true;
API.defaults.headers.common = {
 ...API.defaults.headers.common,
 'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
 'Pragma': 'no-cache',
 'Expires': -1,
} 

const handleSessionExpired = (error) => {
 const isNotAuth = ['authorization', 'auth', 'authorized', 'jwt', 'expired', 'JSONWEBTOKEN', 'Jsonwebtoken'].find(msg=> error?.response?.data?.error?.toLowerCase().includes(msg)
 )
 const cb = () => setTimeout( _ => History.push('/'), 2000),
 message = 'Session expired, please login again.';

 if ([403].includes(error?.response?.status) && isNotAuth) {
  Storage.remove('authToken');
  Storage.remove('user');
  Alert({type: 'error', message, cb, timer: 2500});
 }
 else {
  return Promise.reject(error?.response?.data);
 }
};

const handleSuccess = (response) => {
 return response;
};

API.interceptors.response.use(handleSuccess, handleSessionExpired)

API.interceptors.request.use(config => {
 const cb = () => setTimeout( _ => History.push('/'), 2000),
 message = 'You are not authorized, please login again';

 if(Storage.get('authToken') === null && window.location.pathname !== '/') {
  // Alert({type: 'error', message, cb});
 }

 return config;
}
, error => {
 return Promise.reject(error);
});


export default API;