import React from "react";
import "../Dashboard.css";
import ISearch from "../../../assets/images/search.svg";
import Inotifications from "../../../assets/images/notifications.svg";
import Iback from "../../../assets/images/backArrow.svg";
import Idown from "../../../assets/images/down.svg";
import Dropdown from "../../others/Dropdown";
import { useNavigate } from "react-router-dom";

const TrasactionHeader = ({ user, showNavigation, searchPlaceholder, useSearch, handleSearch, onSubmit, type }) => {
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();

  return (
      <div className="flex" style={{fontFamily: 'Roboto', fontSize: '2em'}}>
      {showNavigation && <div className="">
                  <img
                    className="back-button  ml-3 mt-4 cursor-pointer"
                    onClick={(_) => navigate(-1)}
                    src={Iback}
                    alt="ellipse"
                  />
                </div>}
                {useSearch &&  <div 
                  style={{ borderRadius: "15px", fontFamily: "Roboto" }}
                  className="flex search__sec mt-6 ml-10">
                  <label htmlFor="search">
                    <img
                      src={ISearch}
                      // onSubmit={handleSearch}
                      alt=""
                      className="search__icon cursor-pointer"
                    />
                  </label>
                  <input
                    // style={{ fontSize: "1.1em", fontFamily: "Roboto" }}
                    type="search"
                    id="search"
                    placeholder={` ${searchPlaceholder || 'Type here to search...'} `}
                    onChange={handleSearch}
                  />
                </div>}
        <div className="notification flex ">
          <div className="ml-14">
            <img
              src={Inotifications}
              alt=""
              className="building__icon w-[39p  h-[39px]"
            />
          </div>
          <div className="flex items-center userIcon ">
            <p className=" mt-1 mx-5 capitalize">
              {user?.firstName} {user?.lastName}
            </p>
            <img
              src={Idown}
              alt=""
              className="cursor-pointer mr-8 down p-2"
              onClick={(e) => setShow(!show)}
            />
          </div>
        </div>
        <Dropdown {...{ user, show }} />
      </div>

  );
};

export default TrasactionHeader;
