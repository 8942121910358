/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../../data/SidebarData";
import "./Sidebar.css";
import links from "../../utils/dashLinks";
import Ilogout from "../../assets/images/power.svg";
import Ilogo from "../../assets/images/logo.png";
import Izero from "../../assets/images/zero.svg";
import Iright from "../../assets/images/right.svg";
import Idown2 from "../../assets/images/down2.svg";
import UserAction from "../../actions/user";
import Storage from "../../utils/storage";
import config from "../../utils/config";

function Sidebar() {
  const handleNavigation = (e) => {
    const target = e.target,
      tag = target.getAttribute("data-id")
        ? target
        : target.tagName === "IMG"
        ? target.parentElement
        : null;

    if (tag) {
      document
        .querySelector("aside")
        .querySelector(".active")
        .classList.remove("active");

      tag.classList.add("active");
    }
  };

  const [showUserMenu, setUserMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false)

  const toggleMenu = (_) =>{
      _.stopPropagation();
      setUserMenu(!showUserMenu);
      setDropdown(!dropdown)
    }

  const location = useLocation();
  const [activeLink, setActiveLink] = React.useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
    links.filter(link => link.xtra?.data).map(link => link.xtra.data.map(({url}) => url === activeLink && setDropdown(true)))

  }, []);
  
  const logout = () => {
    UserAction.logout();
  }
  

  return (
    <>
      <section className="main-wrapper no-js-hide " style={{ fontFamily: "Roboto", fontWeight: "700", fontSize: "2em"}}>
        <aside
          onClick={handleNavigation}
          className="pt-0 px-0 mx-0"
          id="aside"
        >
          <div className="sidebar-header-logo w-[69px] h-[32px] m-4">
            <img src={Ilogo} alt="user" />
          </div>
          <div
            className="flex px-0 mx- mb-0 pb-0"
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
              // height: "15%",
            }}
          >
            <div
              className=""
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              
              {links.map((link, index) => {
                const currentUrl =
                  activeLink === link.url ||
                  link.xtra?.data?.find(({ url }) => url === activeLink)?.url === activeLink;
                return JSON.parse(Storage.get('user'))?.roles?.includes(link.auth[0]) && (
                  <div
                    key={index+Math.round(Math.random()*4000)}
                  >
                    {!link.xtra.data ? (
                      <Link
                        to={link.url || "/"}
                        className={`p-0  mt-3`}
                      >
                        <div
                          className={`ml-3 my-6 py-2 flex  sidebar-active ${currentUrl ? "active text-active" : ""}`}
                          data-id=""
                        >
                          <img src={link.icon} alt="" className="" />
                          <p className="text-[11px] font-[500] pl-4">
                            {link.name}
                          </p>
                        </div>
                      </Link>
                    ) : (
                      <>
                        <div
                          data-id="user_menu"
                          key={index+Math.round(Math.random()*9000)}
                          className={`sidebar-active ${
                            currentUrl ? "active" : ""
                          }  flex ml-3 py-2`}
                          onClick={toggleMenu}
                        >
                          <img src={link.icon} alt="" className="" />
                          <p className="text-[11px] font-[500] text-[#263238] pl-4">
                            {link.name}
                          </p>
                          {(showUserMenu || dropdown)  ? ( 
                            <img src={Idown2} alt="" className="ml-2" />
                          ) : (
                          <img src={Iright} alt="" className="ml-3" />
                          )}
                        </div>

                        {(showUserMenu || dropdown) && (
                          <div className="user_menu_wrapper ">
                            {link.xtra.data.map(({ name, url }, indx) => {
                              return (
                                <Link key={indx+Math.round(Math.random()*10000)} to={url || ""} className="flex">
                                  <div className={`flex w-full ml-2  sidebar-active  text-start  h-[36px] ${
                                      activeLink === url ? "text-active" : ""
                                    }`}>
                                  <img src={Izero} alt="" className="w-[13px] h-[13px] mt-2" />
                                  <p className="text-[11px] font-[500] ml-8 mt-2 pb-2">
                                    {name}
                                  </p>
                                  </div>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}

              <div onClick={logout} className="mt-20 ml-3 py-2 flex sidebar-active">
                <img src={Ilogout} alt="" className="text-20 me-2 " />
                <p className="text-[11px] font-[500] text-[#263238] pl-4">
                  Logout
                </p>
              </div>



            </div>
          </div>
        </aside>
      </section>
    </>
  );
}

export default Sidebar;
