/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Table from "../../components/table/Table2";
import { useNavigate } from "react-router-dom";
import ISearch from "../../assets/images/search.svg";
import Inotifications from "../../assets/images/notifications.svg";
import Iback from "../../assets/images/backArrow.svg";
import Idown from "../../assets/images/down.svg";
import Dropdown from "../../components/others/Dropdown";
import HomeLayout from "../../layout/Home";

const AccountPendingTable = () => {
	const [showModal, setModal] = useState(false);
	const [accountPending, setAccountPending] = useState([]);
	const [error, setError] = useState("Loading users...");
	const [isLoading, setIsLoading] = React.useState(false);
	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 4,
		total: 0,
		tempData: {},
	});
	const [show, setShow] = React.useState(false);
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user"));

	const accountsPending = [
		{
			_id: "1",
			name: "Brian knight",
			"Date Created": "11/07/2022",
			status: "Pending",
		},
		{
			_id: "2",
			name: "Jane Bassey",
			"Date Created": "15/07/2022",
			status: "Pending",
		},
		{
			_id: "3",
			name: "Grace Oluyemi",
			"Date Created": "13/07/2022",
			status: "Pending",
		},
		{
			_id: "4",
			name: "Franklin Obunna",
			"Date Created": "11/07/2022",
			status: "Pending",
		},
	];

	async function getPendingAccounts({ setTotalAccounts }) {
		try {
			const { data, success } = { data: { count: 4 }, success: true };
			if (success) {
				setPagination((state) => {
					return { ...state, total: data.count };
				});
				const tmpPending = [];
				const accountsPendingData = accountsPending.map((data) => {
					tmpPending.push(data.name);
					data._data = data;
					return data;
				});
				setAccountPending(accountsPendingData);
				accountsPendingData?.length === 0
					? setError("No pending accounts found")
					: accountsPendingData === (null || undefined)
					? setError("No pending accounts found")
					: setAccountPending(accountsPendingData);
				return accountsPendingData;
			} else setError("No pending accounts found");
		} catch (error) {
			setError(error?.message || "No pending accounts found");
		}
	}

	const handleSearch = async (e) => {
		const { value } = e.target;

		setPagination((state) => {
			return { ...state, search: value };
		});
	};

	useEffect(() => {
	
		let cb = () => {};
		 if(pagination.search) {
				cb = setTimeout(async ()=>{
					setIsLoading(true);
						await getPendingAccounts();
				}, 700)
			} else {
				setIsLoading(true);
				(async () => {
					await getPendingAccounts();
				})();
			}

			return () => {
				clearTimeout(cb)
			}
	}, [pagination.page, pagination.search]);

	return (
		<HomeLayout {...{showNavigation: true, useSearch: true, handleSearch}}>
			<div className="mt-[10%]">
				<div className="flex justify-between   ">
					<h1 className="font-[500] mb-14 text-[24px] text-[#263238]">
						Accounts
					</h1>
				</div>
				<div className="text-start ">
					<div className="table-one rounded-t-[10px] w-[1108px">
						<div className="  bg-[#F9F9F9] flex justify-between  py-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">
								Pending Accounts
							</h1>
						</div>
						{accountPending?.length ? (
							<Table
								{...{
									getPendingAccounts,
									data: accountsPending,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</div>
			</div>
		</HomeLayout>
	);
};

export default AccountPendingTable;
