/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Storage from "../../utils/storage";
import History from "../../utils/history";
import { useEffect } from "react";
import UserAction from "../../actions/user";
import links from "../../utils/dashLinks";

const OTP = ({ error, setError, email }) => {
	const [state, setState] = useState({
		ready: false,
		token: Array(8).fill(""),
	});

	const handlePaste = ({ target: { value } }) => {
		if (value && value.length === 8 && !isNaN(value)) {
			setState({ token: value.split(""), ready: true });
		}
	};

	const handleInput = ({ code, key, target }, i) => {
		if (code !== "Backspace") {
			if (isNaN(key)) {
				target.classList.add("border-danger");
			} else {
				if (!state.ready) {
					setState({
						...state,
						token: [...state.token.map((val, idx) => (idx === i ? key : val))],
					});
					target?.nextElementSibling?.focus();
					target.classList.remove("border-danger");
				}
			}
		} else {
			setState({
				ready: false,
				token: [...state.token.map((val, idx) => (idx === i ? "" : val))],
			});
			target?.previousElementSibling?.focus();
		}
	};

	const handleSubmit = (_) => {
		UserAction.verifyToken({
			payload: { email, token: state.token.join("") },
		})
			.then((res) => {
				if (!res.success) {
					setError(res.message);
				} else {
					setState({ ready: false, token: [] });
					Storage.set("authToken", res.data.authToken);
					Storage.set("user", res.data.user);

          /*** Navigate to dashboard if permission is available***/ 
					if (res.data.user.roles.includes("view_dashboard"))
						History.push("/dashboard");
					else {
						const userRoles = res.data.user.roles
						.filter((role) => role.startsWith("get"))
						// navigate to any available permissions' screen 
						links.forEach((link) => {
									if (!link.xtra && userRoles.includes(link.auth?.[0])) {
										if(link?.url) History.push(link?.url);
									} else {
										link?.xtra?.data?.forEach(link => {
											if(userRoles.includes(link?.auth)) History.push(link?.url);
										})
									};
						});
					}
				}
			})
			.catch((err) => {
				setState({ ready: false, token: [] });
				setError(err.message);
			});
	};
	useEffect(() => {
		if (error) setError("");
		if (state.token.filter(Boolean).length === 8) {
			setState({ ...state, ready: true });
		}
	}, [state.token]);

	return (
		<div className="App ">
			<div className="text-start ">
				<h1 className="custom-valid text-[#525252] text-[26px]  font-bold ">
					Verify Token
				</h1>
				<div className="text-center ">
					<p className="custom-valid2 text-left  pt-8">
						Input the verification code sent to your email
					</p>
				</div>
			</div>
			<div className="login-form text-left">
				<div
					className="flex justify-between token-form"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{state.token.map((token, i) => {
						return (
							<input
								className="w-full bg-[#fff] border-2 text-center tokenInput mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								type="password"
								name="token"
								id="token"
								placeholder="*"
								value={token}
								key={i}
								onKeyPress={e=> (state.token.length === 8 && e.code==='Enter') && 	handleSubmit()}
								onChange={handlePaste}
								onFocus={(e) => e.target.select()}
								onKeyUp={(e) => handleInput(e, i)}
							/>
						);
					})}
				</div>
				<p className="flex justify-center text-center font-[400]   text-[16px] text-[#263238] pt-8 pl-14 ml-10">
					{/* This code will expire in 0:04 secs */}
				</p>
				<div className="flex items-center justify-center">
					<button
						className="login-custom-btn w-full  m-auto mt-8"
						type="button"
						disabled={!state.ready}
						onClick={handleSubmit}>
						Verify Token
					</button>
				</div>
			</div>
		</div>
	);
};

export default OTP;
