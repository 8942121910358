import Storage from "./storage";

export const Roles = {
  admin: "admin",
  superadmin: "superadmin",
  agent: "agent",
  branchManager: "branchManager",
  csm: "csm",
};
export const permissions = {
  viewDashboard: "view_dashboard",
  getLogs: "get_logs",
  getRoles: "get_roles",
  getCardRequests: "get_cardrequests",
  getAccounts: "get_accounts",
  getUsers: "get_users",
  getWallet: "get_wallets",
  viewSettings: "view_settings",
};

export const validateEmail = (email) => {
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  return regexExp.test(email);
};

export const Statuses = {
  successful: "successful",
  pending: "pending",
  failed: "cif",
};

export const getRole = () => {
  let loggedInUser = Storage.get("user");
  if (loggedInUser) {
    loggedInUser = JSON.parse(loggedInUser);
  }
  return loggedInUser?.role;
};

/**
 * @param {object} data   takes in an object of boolean and number values
 * @param {boolean} data.previewInConsole  whether to preview the data/size in the console, default is true
 * @param {number} data.size  the actual size of the data/file in byte, default is 50000000
 * @returns  {number}  The size of the data/file
 **/
export const getFileSize = function (data = {}) {
  data.previewInConsole = data.previewInConsole ? data.previewInConsole : false;
  data.size = data.size !== (undefined || null || "") ? data.size : 50000000; // 50mb
  data.size = Number(data.size);
  const k = 1000;
  const format = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(data.size) / Math.log(k));
  const size = parseFloat(data.size / Math.pow(k, i)).toFixed(1);

  if (data.previewInConsole === true)
    console.info(data.size, " = ", size + format[i]);
  return size + " " + format[i];
};
