/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Modal from "../../layout/Modal";
import Input from "../form/inputOne";
import Button from "../form/button/Button";
import "./ModalOne.css";
import { FaTimes } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";
import User from "../../actions/user";

const List = styled.div`
	display: inline-block;
	background: #333;
	padding: 0.5rem;
	color: var(--C_white);
	border-radius: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
`;

export default function RejectCard({
	showModal,
	setModal,
	data,
	getUsers,
}) {

 let loading = false;
 const handleReject = React.useCallback(async () => {
  loading = true
  User.rejectCardRequest(data.id).then(res => {
    loading = false
  })
  .catch(err=>{
   loading = false
  })
 })

	return (
		<Modal
			{...{
				showModal,
				setModal,
				// top: "1em",
				width: "700px",
				height: "600px",
			}}>
			<form
				className="mt-5 mx-10"
				onSubmit={(e) => {
					e.preventDefault();
     handleReject()
				}}>
				<div className="my-5 flex justify-between items-center">
					<h1 className="py-0 mt-3 text-center leading-7" style={{fontSize: '20px'}}>
						Reject Card Request
					</h1>

					<Button
						onClick={(_) => {
							setModal(state=>({...state, reject: false}))
						}}
						variant={"secondary"}
						value={
							<GrClose
								size={"20px"}
								className="font-bold  mx-[5px]  py-0 text-white "
							/>
						}
						wrapperClass={"px-2 mt-3"}
					/>
				</div>

				<div>
					<label htmlFor="reason" className="mb-20 text-start block">
						Reason
					</label>
					
     <textarea placeholder="Enter your reason..." style={{width: '100%', padding: '5px 10px'}} rows="10"></textarea>
				</div>

				<Button
					wrapperClass={"mt-10"}
					value={loading ? "Submitting..." : "Submit"}
					width={"100%"}
					type={"submit"}
					disabled={loading}
				/>
			</form>
		</Modal>
	);
}
