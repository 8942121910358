import React from 'react'
import Sidebar from './sidebar/Sidebar'

const AccountSecurity = () => {
  return (
    <div className="">
        <Sidebar />
    </div>
  )
}

export default AccountSecurity