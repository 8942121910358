/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import IbarChat from "../../../assets/images/barChatIcon.svg";
import Ibox1 from "../../../assets/images/box1.svg";
import Ibox2 from "../../../assets/images/box2.svg";
import Ibox3 from "../../../assets/images/box3.svg";
import I3dots from "../../../assets/images/3dots.svg";
import Ibase1 from "../../../assets/images/base1.svg";
import BACKEND from "../../../utils/backend";
// import BarChat from '../../charts/BarChat';


const RegisteredToday = () => {

  return (
    <div className="wraper-dash pl-3 mt-10 mx-4 text-start h-[200px]  py-5 pr-2  rounded-[5px] bg-[#F7F9FB]">
      <div className="flex ">
                  <div className="pl-5 ">
                    <p className="text-[#7995AC] text-[14px] font-[400] pt-4 ">
                    Leo, you opened
                    </p>
                    <p className="text-[#010918]  text-[24px] font-[500] pt-4 ">
                      2
                    </p>
                    <p className="text-[#010918] text-[14px] font-[400] py-4 ">
                      Accounts Pending
                    </p>
                  </div>
                  <div className=" ">
                    <div className="flex w-[50px] mt-4">
                      <img
                        className="  rounded-md mt-14 ml-8 "
                        src={IbarChat}
                        alt="money"
                      />
                      
                    </div> 
                    {/* //add bar chat Here
                    {/* <BarChat  /> */}

                    <img
                      className="w-[44px] h-[3px]  ml-8  mt-1"
                      src={Ibase1}
                      alt="money"
                    />
                  </div>
                </div>
                <div className=" flex ">
                  <img
                    className="w-[8px] h-[8px] mt-1 ml-3"
                    src={Ibox3}
                    alt="money"
                  />
                  <p className="text-[000000] text-[7px] font-[400] ml-3 mt-1">
                  CIF Generated
                  </p>
                  <img
                    className="w-[8px] h-[8px] mt-1 ml-3"
                    src={Ibox2}
                    alt="money"
                  />
                  <p className="text-[000000] text-[7px] font-[400] ml-3 mt-1">
                  Pending
                  </p>
                  <img
                    className="w-[8px] h-[8px] mt-1 ml-3"
                    src={Ibox1}
                    alt="money"
                  />
                  <p className="text-[000000] text-[7px] font-[400] ml-3 mt-1">
                    Account Generated
                  </p>
                </div>
    </div>
  )
}

export default RegisteredToday;