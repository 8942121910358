/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import "./User.css";
import IPlus from "../../assets/images/plus2.svg";
import Table from "../../components/table/Table";
import Modal from "../../components/modal/ModalOne";
import RoleAction from "../../actions/roles";
import UserAction from "../../actions/user";
import { FiDownloadCloud, FiUploadCloud } from "react-icons/fi";
import UploadUsers from "../../components/modal/UploadUsers";
import HomeLayout from "../../layout/Home";
import Delete from "../../components/modal/Delete";
import config from "../../utils/config";
import CreateRm from "../../components/modal/CreateRm";

const RMs = () => {
	const [showModal, setModal] = useState(false);
	const [users, setUsers] = useState([]);
	const [error, setError] = useState("Loading RMs...");
	const [modals, setModals] = useState({ upload: false });
	const [isLoading, setIsLoading] = React.useState(false);
  const [deleteUser, setDeleteUser] = useState({state: false, data: {}})
	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		length: 0,
		role: "",
		date: new Date(),
		search: "",
	});

	const dlUrl = () => {
		let url = config.backendUrl.endsWith('/') ? config.backendUrl+'auth/downloadrms' : config.backendUrl+'/auth/downloadrms';

		return url;
	}
	const [modalData, setModalData] = React.useState({
		actionName: "addRM",
	});

	const getRMs = async () => {
		try {
			const users = await UserAction.getRMs(pagination);
			function loadUsers({ data }) {
				setIsLoading(false);
				setPagination((state) => {
					return {
						...state,
						total: data?.count,
						length: pagination.pageSize * pagination.page,
					};
				});

				const userData = data?.regionalmanagers.map((res) => {
					const {
						accountOfficerId,
						accountOfficerName,
						updatedAt,
						id,
					} = res;
					return {
						_id: id,
						Name: accountOfficerName || "Nil",
						"RM ID": accountOfficerId || "Nil",
						"Last Updated": `${updatedAt ? new Date(updatedAt).toDateString() : "Nil" }`,
						_data: res,
					};
				});

				userData.length === 0
					? setError("No users found")
					: userData === (null || undefined)
					? setError("Error loading RMs")
					: setUsers(userData);
				return userData;
			}
			loadUsers(users);
		} catch (error) {
			setError("Error loading RMs");
		}
	};

	
	useEffect(() => {
	
		let cb = () => {};
		 if(pagination.search) {
				cb = setTimeout(async ()=>{
					setIsLoading(true);
						await getRMs();
				}, 700)
			} else {
				setIsLoading(true);
				(async () => {
					await getRMs();
				})();
			}

			return () => {
				clearTimeout(cb)
			}
	}, [pagination.page, pagination.date, pagination.search]);


	const handleSearch = async (e) => {
		const { value } = e.target;

		setPagination((state) => {
			return { ...state, search: value };
		});
	};


	// const onChange = async (date) => {
	// 			setPagination(state=> {
	// 				return {
	// 					...state,
	// 					date,
	// 					page:1 
	// 				}
	// 			})
			
	// 	}
	//delete user by uuid and refresh the page
	const handleDelete = async (uuid, cb) => {
		try {
      let res = await UserAction.deleteRM(uuid);
      cb()
			if(res.success) {
        setPagination(state=> ({...state, search: ""}))
        getRMs()
        setDeleteUser((state) => ({ ...state, state: false }));
      }
		} catch (error) {
			setError(error.response.data.message);
			setIsLoading(false);
		}
	};

	//update user by uuid and refresh the page
	const handleEdit = async (userId) => {
		try {
			await UserAction.updateUser(userId);
			setIsLoading(true);
			setTimeout((state) => 3000);
			getRMs();
		} catch (error) {
			setError(error.response.data.message);
			setIsLoading(false);
		}
	};

	const dropdownMenu = [
		{
			text: "Edit",
			action: (e, data) => {
				setModalData({
					actionName: "updateRM",
					id: data?._data?.id,
					data: data._data,
				});
				setModal(true);
			},
		},
		{ text: "Delete", action: (e, prop) => {
      setDeleteUser({
        state: true,
        data: {
          action: (cb) => handleDelete(prop._data.id, cb),
          name: prop._data.firstName +" "+ prop._data.lastName,
        },
      });
    } },
	];

	return (
		<HomeLayout {...{ useSearch: true, showNavigation: true, handleSearch }}>
			<div className="mt-[10%]">
				<div className="flex justify-between">
					<h1 className="font-[500] mb-5 text-[24px] text-[#263238]">
						Regional Managers
					</h1>
				</div>
					<div className="flex justify-between items-center mb-8">
						
						<div className="flex justify-end">
						<a
							className="flex items-center mr-5 h-[45px] px-3 cursor-pointer bg-[#000] text-white rounded-md decoration-none" href={dlUrl()}>
							<FiDownloadCloud size={20} className="mr-2" />
							<span>Download RMs</span>
						</a>

						<div
							className="flex new_user__btn_div h-[45px] px-3 mr-5 cursor-pointer"
							onClick={(e) => setModal((_) => !showModal)}>
							<img src={IPlus} alt="" className="w-5 mr-2" />
							<button
								className="text-[#FF0000]"
								onClick={(_) => {
									setModalData({
										actionName: "addRM",
										data: null,
									});
								}}>
								Add new RM
							</button>
						</div>

						<div
							className="flex items-center new_user__btn_di h-[45px] px-3 cursor-pointer bg-[#FF0000] rounded-md"
							onClick={(e) =>
								setModals((state) => ({ ...state, upload: true }))
							}>
							<FiUploadCloud size={20} className="text-white" />
							<button className="text-white ml-3">Upload RMs</button>
						</div>
						</div>
					</div>

				<section className="w-full pb-5">
					<div className="table-one rounded-t-[10px]">
						<div className=" h-[50px]  bg-[#F9F9F9] text-start pt-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">RMs</h1>
						</div>
						{users.length ? (
							<Table
								{...{
									dropdownMenu,
									handleEdit,
									getRMs,
									setError,
									data: users,
									handleDelete,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</section>
			</div>

			{showModal && (
					<CreateRm
					getRMs={getRMs}
					showModal={showModal}
					setModal={setModal}
					data={modalData}
				/>
			)}
			<UploadUsers
				{...{
					title: 'RMs',
					action: 'uploadRMs',
					getRMs,
					showModal: modals.upload,
					setModal: (val) => setModals((state) => ({ ...state, upload: val })),
				}}
			/>

<Delete
				{...{
					setDeleteModal: (val) =>
          setDeleteUser((state) => ({ ...state, state: val })),
					showDeleteModal: deleteUser.state,
					data: deleteUser.data,
				}}
			/>
		</HomeLayout>
	);
};

export default RMs;
