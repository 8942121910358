/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Ipp1 from "../../assets/images/pp1.png";
import { Link, useLocation } from "react-router-dom";
import UserAction from "../../actions/user";
import Table from "../../components/table/Table";
import Avatar from "../../assets/images/avatar.png"


const AccountInQueTable = ({setTotalAccounts}) => {
  
  const [accounts, setAccounts] = useState([]);
  const [showModal, setModal] = useState(false);
  const [error, setError] = useState("Loading accounts...");
  const [isLoading, setIsLoading] = React.useState(false);
  const [timeOut, setTimeOut] = useState(3000);

	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 2,
		total: 0,
		length: 0,
		role: "",
		search: "",
    status: "",
    hidden: true
	});

  const getAccounts = React.useCallback(async function() {
      try {
        setIsLoading(true);
        const accounts = await UserAction.getAccounts(pagination);
     
        function loadAccounts({ data }) {
          setTotalAccounts(data?.count)
          setIsLoading(false);
          setPagination((state) => {
            return {
              ...state,
              total: data?.count,
              length: pagination.pageSize * pagination.page,
            };
          });

      const accountData = data?.accounts?.map(
        ({
          name,
          type,
          accountNumber,
          status,
          id,
          passport
        }) => {
          return {
            Image: (passport?.startsWith("file") || passport === null) ? Avatar : passport,
            _id: id,
            name: name,
            type: type,
            accountNumber,
            status: status,
          };
        }
      );

      accountData.length === 0 
        ? setError("No account in the system at the moment")
        : accountData === (null || undefined)
        ? setError("No account in the system at the moment")
        : setAccounts(accountData);
      return accountData;
      }
      loadAccounts(accounts);
    } catch (error) {
      setError("Error loading accounts");
    }
  },[pagination.page])

  useEffect(() => {
    getAccounts()
  }, [])
  // useEffect(() => {
  //   setIsLoading(true);
  //   console.log(pagination.page);
  //   (async () => {
  //     await getAccounts();
  //   })();
  //   }, []);

    useEffect(() => {
      if (timeOut === 0) {
        getAccounts();
      }
    } , [timeOut]);

    const handleTimeOut = () => {
      setTimeOut(state => (state - 50 <=  0 ? 0 : state - 50));
    };
    
    if (pagination.search) setInterval(handleTimeOut, 100);
    else clearInterval(handleTimeOut);

  return (
    <div className="text-start ">
      <div className="table-one rounded-t-[10px] w-full">
        <div className="  bg-[#F9F9F9] flex justify-between  py-4 px-7 ">
          <h1 className=" font-[400] text-[14px] text-[#010918] ">
            Accounts Created
          </h1>
          <Link to="/accounts" key="">
            <h1 className=" font-[400] text-[14px] text-[#010918] ">
              View All
            </h1>
          </Link>
        </div>
        {accounts.length ? (
          <Table
          {...{
            getAccounts,
            data: accounts,
            showModal,
            setModal,
            isLoading,
            pagination,
            noPagination: true
            // setPagination,
          }}
        />
        

       ) : (
        <p className="text-center">{error}</p>
      )}
      </div>
    </div>
  );
};

export default AccountInQueTable;
