import { Outlet,  Navigate} from "react-router-dom";
import React from 'react';
import Storage from "./storage";
import config from "./config";

export default function ProtectedRoutes() {
  return (
   <>
    {Storage.get("authToken") === null ? Navigate({to: config.pageUrls.login}) : <Outlet /> }
    </>
  )
}