/* eslint-disable no-unused-vars */
import Alert from "../utils/alert";
import BACKEND from "../utils/backend";
import history from "../utils/history";
import Storage from "../utils/storage";
import AES from "crypto-js/aes";
import config from "../utils/config";
import links from "../utils/dashLinks";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default class User {
  static BE = new BACKEND();
  static login(e, { setError, setScreen, setDisabled, setMsg }) {
    e.preventDefault();
    setDisabled(true);
    const TG = e.target,
      email = TG.email.value,
      password = TG.password.value,
      deviceId = "deviceId=" + email + "-" + Date.now();

    if (!document.cookie || !getCookie("deviceId")) document.cookie = deviceId;

    console.log(getCookie("deviceId"));

    //allow only admin and super
    User.BE.login({
      email,
      password: AES.encrypt(password, config.encryptionKey).toString(),
      deviceType: "adminWeb",
      deviceId: getCookie("deviceId"),
    })
      .then((res) => {
        setDisabled(false);
        if (res.success) {
          Alert({
            type: "success",
            message: res.message,
          });
          if (res?.data?.newUser) {
            setMsg("change your password");
            setScreen("new-user");
          } else {
            setMsg("complete login");

            if (!res?.data?.authToken) setScreen("OTP");
            else {
              console.log(res.data.user.roles.includes("view_dashboard"));
              Storage.set("authToken", res.data.authToken);
              Storage.set("user", res.data.user);

              /*** Navigate to dashboard if permission is available***/
              if (res.data.user.roles.includes("view_dashboard"))
                history.push("/dashboard");
              else {
                const userRoles = res.data.user.roles.filter((role) =>
                  role.startsWith("get")
                );
                // navigate to any available permissions' screen
                links.forEach((link) => {
                  if (!link.xtra && userRoles.includes(link.auth?.[0])) {
                    if (link?.url) history.push(link?.url);
                  } else {
                    link?.xtra?.data?.forEach((link) => {
                      if (userRoles.includes(link?.auth))
                        history.push(link?.url);
                    });
                  }
                });
              }
            }
          }
        } else {
          setError(res.message);
        }
      })
      .catch((e) => {
        setDisabled(false);
        console.error(e);
      });
  }

  static verifyToken({ payload }) {
    return User.BE.send({
      to: "/auth/verifyauth",
      type: "post",
      payload: { ...payload, deviceId: getCookie("deviceId") },
    });
  }

  static addRM(payload) {
    return User.BE.send({
      to: "/seed/regional-managers/add-rms",
      type: "post",
      payload,
    });
  }

  static addBranch(payload) {
    return User.BE.send({
      to: "/seed/branch-codes/branch-code",
      type: "post",
      payload,
    });
  }

  static updateBranch(payload, branchId) {
    return User.BE.send({
      to: `/seed/branch-codes/update-branch-codes/${branchId}`,
      type: "patch",
      payload,
    });
  }

  static deleteBranch(branchId, cb) {
    return User.BE.send({
      to: `/seed/branch-codes/branch-code/${branchId}`,
      type: "delete",
      cb,
      useAlert: true,
    });
  }

  static deleteRM(rmId, cb) {
    return User.BE.send({
      to: `/seed/regional-managers/rms/${rmId}`,
      type: "delete",
      cb,
      useAlert: true,
    });
  }

  static uploadBranches(payload) {
    return User.BE.send({
      to: "/seed/branch-codes/add-bulk",
      type: "post",
      payload,
    });
  }

  static uploadRMs(payload) {
    return User.BE.send({
      to: "/seed/regional-managers/add-bulk",
      type: "post",
      payload,
    });
  }

  static updateRM(payload, rmId) {
    return User.BE.send({
      to: `/seed/regional-managers/update-rms/${rmId}`,
      type: "patch",
      payload,
    });
  }

  static async validateToken(payload, cb) {
    const response = await User.BE.send({
      to: "/auth/verifyauth",
      type: "post",
      payload,
      cb,
    });

    return response;
  }

  static async resetPassword(e, payload, cb) {
    e.preventDefault();
    Alert({
      type: "info",
      message: "Processing request...",
      timer: 5000,
    });
    const response = await User.BE.send({
      to: "/auth/resetpassword",
      type: "post",
      payload,
      cb,
    });
    return response;
  }

  static forgotPassword(e, { email, setError, setScreen, setDisabled }) {
    e.preventDefault();
    setDisabled(true);
    User.BE.send({
      to: "/auth/forgotpassword",
      type: "post",
      payload: {
        email,
      },
    })
      .then((res) => {
        if (res.success) {
          Alert({
            type: "success",
            message: res.message,
          });
          setScreen("token");
        } else {
          setError(res.message);
        }
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(false);
        console.error(e);
        setError(e?.message);
      });
  }

  static changePassword(e, { setScreen, setError, email, setDisabled }) {
    e?.preventDefault();
    const TG = e.target,
      // confirmpassword = TG?.Cpassword?.value,
      newpassword = TG?.newpassword?.value;
    return new Promise((resolve, reject) => {
      e.preventDefault();
      setDisabled(true);
      // if (newpassword !== confirmpassword) {
      //   setError("password does not match");
      //   setDisabled(false);
      //   return reject();
      // }

      User.BE.send({
        to: "/auth/changepassword",
        type: "patch",
        payload: {
          email: e.target.email.value,
          newpassword,
        },
      })
        .then((res) => {
          if (res.success) {
            Alert({
              type: "success",
              message: res.message,
            });
            setScreen("login");
          } else {
            setError(res.message);
          }
          setDisabled(false);
          resolve();
        })
        .catch((e) => {
          setError(e.message);
          setDisabled(false);
          reject();
        });
    });
  }

  static createUser(payload, cb) {
    return User.BE.send({
      to: "/auth/createuser",
      type: "post",
      payload,
      cb,
      useAlert: true,
    });
  }

  static getUsers({ pageSize, page, search, date } = {}) {
    return User.BE.send({
      to: `/auth/users/?pageSize=${pageSize}&page=${page}&search=${search}&date=${date}`,
      type: "get",
      useAlert: false,
    });
  }

  static getRMs({ pageSize, page, search, date } = {}) {
    return User.BE.send({
      to: `/seed/regional-managers/get-rms?pageSize=${pageSize}&page=${page}&search=${search}`,
      type: "get",
      useAlert: false,
    });
  }

  static getCardRequest({ pageSize, page, status, search } = {}) {
    return User.BE.send({
      to: `/cards/cardrequests/?pageSize=${pageSize}&page=${page}&status=${status}&search=${search}`,
      type: "get",
      useAlert: false,
    });
  }

  static approveCardRequest(requestId) {
    return User.BE.send({
      to: `/cards/cardrequests/approve/${requestId}`,
      type: "get",
      useAlert: true,
    });
  }

  static rejectCardRequest(payload, requestId, cb) {
    return User.BE.send({
      type: "patch",
      payload,
      cb,
      to: `/cards/cardrequests/deny/${requestId}`,
      useAlert: true,
    });
  }

  static getLogs({ pageSize, page, role } = {}) {
    return User.BE.send({
      to: `/logs/?pageSize=${pageSize}&page=${page}&search=${role}`,
      type: "get",
      useAlert: false,
    });
  }

  static getSolId({ pageSize, page } = {}) {
    return User.BE.send({
      to: `/seed/branch-codes/get-branch-codes?view=web&pageSize=${pageSize}&page=${page}`,
      type: "get",
      useAlert: false,
    });
  }

  static getRegionalManager() {
    return User.BE.send({
      to: "/seed/regional-managers/get-rms",
      type: "get",
      useAlert: false,
    });
  }

  static filterUsers({ pageSize, page, search, role } = {}) {
    return User.BE.send({
      to: `/auth/users/?pageSize=${pageSize}&page=${page}&search=${search}&role=${role}`,
      type: "get",
    });
  }

  static updateUser(payload, id, cb) {
    return User.BE.send({
      to: `/auth/users/${id}`,
      type: "patch",
      payload,
      cb,
      useAlert: true,
    });
  }

  static deleteUser(id, cb) {
    return User.BE.send({
      to: `/auth/users/${id}`,
      type: "delete",
      cb,
      useAlert: true,
    });
  }

  static unlockUser(email, cb) {
    return User.BE.send({
      to: `/auth/unlockusers`,
      type: "post",
      cb,
      payload: { email },
      useAlert: true,
    });
  }

  static lockUsers(email, cb) {
    return User.BE.send({
      to: `/auth/lockusers `,
      type: "post",
      cb,
      payload: { email },
      useAlert: true,
    });
  }

  static resetUsers(email, cb) {
    return User.BE.send({
      to: `/auth/resetusers`,
      type: "post",
      cb,
      payload: { email },
      useAlert: true,
    });
  }

  static getAccounts({ pageSize, page, search, status } = {}) {
    return User.BE.send({
      to: `/accounts/getaccounts/?pageSize=${pageSize}&page=${page}&search=${search}&status=${status}`,
      type: "get",
      useAlert: false,
    });
  }

  static filterAccountsByDate({ pageSize, page, date } = {}) {
    return User.BE.send({
      to: `/accounts/getaccounts/?pageSize=${pageSize}&page=${page}&from=${date.start}&to=${date.end}`,
      type: "get",
      useAlert: false,
    });
  }

  static async uploadUsers({ payload, setDisabled, cb }) {
    try {
      const res = await User.BE.send({
        to: "/auth/addusersbulk",
        type: "post",
        payload,
        headers: this.headers,
        useAlert: true,
      });
      if (res?.success) {
        cb();
      } else {
        setDisabled(false);
      }
    } catch (e) {
      console.error(e);
      setDisabled(false);
    }
  }

  static getUserInfo(id) {
    return User.BE.send({
      to: `/auth/users/${id}`,
      type: "get",
    });
  }

  static logout() {
    User.BE.send({
      to: `/auth/account/setup/logout`,
      type: "get",
    })
      .then((res) => {
        if (res.success) {
          Storage.remove("authToken");
          Storage.remove("user");
          history.push("/");
        }
      })
      .catch((err) => {
        Alert({ type: "error", message: "An error occurred" });
      });
  }
}
