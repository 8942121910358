import Alert from '../utils/alert';
import BACKEND from '../utils/backend';



export default class Role {
  static BE = new BACKEND();

  static addPermissionToRole(roleId, payload, cb) {
    return this.BE.send({
      to: "/roles/add/" + roleId,
      type: "post",
      payload,
      cb,
      useAlert: false,
    });
  }



  static removePermissionsFromRole(roleId, payload) {
    return this.BE.send({
      to: "/roles/remove/" + roleId,
      type: "post",
      payload,
      useAlert: false,
    });
  }


  static async createRole(payload, cb) {
    const res = await this.BE.send({
      to: "/roles",
      type: "post",
      payload,
      cb,
      useAlert: true,
    });

    if (res.success) {
      await this.addPermissionToRole(res.data.id, payload).then(console.log).catch(console.error)
    }
    return res
  }

  static async getRoles({
    pageSize,
    page
  } = {}) {
    const res = await this.BE.send({
      to: `/roles/?pageSize=${pageSize}&page=${page}`,
      type: "get",
      useAlert: false,
    });
    return res
  }



  static async deleteRole(id, permissions) {
    const res = await this.BE.send({
      to: `/roles/${id}`,
      type: "delete",
      useAlert: false,
    });

    if (res.success) {
      Alert({
        type: "success",
        message: "Role deleted successfully"
      })
      await this.removePermissionsFromRole(id, permissions)
    } else Alert({
      type: "success",
      message: res.message
    })
    return res
  }

  static async updateRole(id, payload, cb, role) {
    try {
      let res = await this.BE.send({
        to: "/roles/" + id,
        type: "patch",
        payload,
        cb,
        useAlert: false,
      });

      const old = role?.permissions?.map(({
          name
        }) => name),
        updated = payload?.permissions;

      let newPermissions = old.length > updated.length || old.length < updated.length ? updated.filter(n => old.indexOf(n) > -1 ? false : true) : updated.filter(n => old.indexOf(n) > -1 ? false : true);
      let removePermissions = old.filter(n => !old.filter(n => updated.includes(n)).includes(n))


      if (newPermissions.length) await this.addPermissionToRole(id, {
        permissions: newPermissions
      }).then(console.log).catch(console.error)
      if (removePermissions.length) await this.removePermissionsFromRole(id, {
        permissions: removePermissions
      });

      if (!res.success && newPermissions.length === 0 && removePermissions.length === 0) Alert({
        type: "info",
        message: "No update was made"
      })
      else if (!res.success && (newPermissions.length || removePermissions.length)) {

        Alert({
          type: "success",
          message: `Permission(s) updated successfully`
        })

        res.success = true;
      }
      else if (res.success && (newPermissions.length || removePermissions.length)) {

        Alert({
          type: "success",
          message: `Role and Permission(s) updated successfully`
        })
      }
      else {
        Alert({
          type: res.success ? 'success' : "error",
          message: res.message
        })
      }
      return res
    } catch (err) {
      console.error(err);
    }
  }

  static async getPermissions({
    pageSize,
    page
  } = {}) {
    const res = await this.BE.send({
      to: `/roles/permissions/?pageSize=${pageSize*300}&page=${page}`,
      type: "get",
      useAlert: false,
    });
    return res
  }
}