/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UserAction from "../../actions/user";
import Imask from "../../assets/images/mask.svg";
import ModalOne from "../../layout/Modal";
import Button from "../form/button/Button";

const ViewPermission = ({ showModal, setModal, data }) => {
    
    const [disabled, setDisabled] = React.useState(false);
    
  const handleClose = () => {
    setModal(false);
  };
  const permissions = data?.permissions?.map(({name}) => name);
 
  return (
    <ModalOne {...{showModal, setModal, width: "650px", height: "350px"}}>
      <div>
          <div>
            <div className="flex justify-between">
              <h2 className="text-start  py-0 mt-3  font-[700] text-[25px] text-[#000000]">
                Role Details
              </h2>
              <img
                src={Imask}
                alt=""
                className="py-0 mt-5 mr-5 cursor-pointer"
                onClick={handleClose}
              />
            </div>
          
          <div className="leading-5 text-start mt-5">
          <h2 className="underline text-[18px] font-[600]">
            Name
          </h2>
          <p className="capitalize mt-2">{data.name}</p>
          </div>

          <div className="leading-6 text-start mt-5">
          <h2 className="underline text-[18px] font-[600]">
            Permissions
          </h2>
           <p className="capitalize mt-2 ">{ permissions?.length > 0 ? permissions?.join(', ')+'.' : 'N/A'}</p>
          </div>
        </div>
        </div>
    </ModalOne>
  )
};

export default ViewPermission;
