import React from 'react';
import Input from "../../components/form/inputOne";
import IUser from "../../assets/images/user.svg";

const Token = ({email, action, setScreen}) => {
    return (
          <div
            className="login-form py-10 w-[500px]"
          >
            <p className="text-[#525252] text-start font-bold text-[26px] my-8"> Enter Token </p>
            <Input
              name={"email"}
              placeholder={" "}
              type={"email"}
              icon={IUser}
              disabled={true}
              value={email}
              label='Email Address'
              inpClass={`my-0 pr-2 bg-white`}
              inputClass='px-2'
              labelClass='text-lg'
            />
            <Input
              name={"token"}
              placeholder={" "}
              type={"text"}
              id={"token"}
              wrapperClass={"mt-5"}
              inputClass='px-2'
              label={'Token'}
              inpClass='my-0'
              labelClass='text-lg'
            />
            <div className="text-end mb-3">
              <a
                className="inline-block mt-3 align-baseline font-bold text-sm text-[#263238] hover:text-blue-800"
                href="/"
                onClick={(e) => setScreen("login")}
              >
                Back to login?
              </a>
            </div>
            <button className="login-custom-btn w-full" type="submit">
              Continue
            </button>
          </div>
    );
}

export default Token;

