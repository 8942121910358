import React, { useEffect } from "react";
import "./App.css";
import Login from "././pages/Login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import CreateAdmin from "./components/CreateAdmin.jsx";
import Profile from "./pages/user/Profile";
import AccountSecurity from "./components/AccountSecurity.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import User from "./pages/user/User";
import RMs from "./pages/user/RMs";
import User1 from "./pages/user/User1.0";
import Role from "./pages/role/Role";
import AllTransactionsTable from "./pages/transactions/AllTransactionsTable";
import QueTable from "./pages/accountInQue/QueTable";
import FailedItemsTable from "./pages/faildItems/FaildItemsTable";
import AccountPendingTable from "./pages/accountsPending/AccountPendingTable";
import WalletTable from "./pages/wallet/WalletTable";
import Settings from "./pages/settings/Settings";
import Log from "./pages/Log/Log";
import PendingCardRequest from "./pages/cardRequest/PendingCardRequest";
import Profile1 from "./pages/accountInQue/Profile";
import Branches from "./pages/user/Branches";
import { useHomeContext } from "./context/Home";
import config from "./utils/config";
import Alert from "./utils/alert";
import UserAction from "./actions/user";
import ProtectedRoutes from "./utils/ProtectedRoute";

function App() {
  const { pageUrls } = config;
  const { resetTimer, timer, setTimer } = useHomeContext();

  useEffect(() => {
    if (window.location.pathname !== pageUrls.login) {
      if (timer === 10) {
        Alert({
          type: "info",
          timer: 10000,
          message: `Idleness detected: Logging you out in ${timer} seconds!`,
        });
      }

      let idleInterval = setInterval(() => {
        if (timer > 0) {
          setTimer((timer) => timer - 1);
        } else {
          Alert({ type: "info", message: "Logging you out now..." });
          UserAction.logout()
          clearInterval(idleInterval);
        }
      }, 1000);

      return () => {
        clearInterval(idleInterval);
      };
    }
  }, [timer]);

  document.addEventListener("click", resetTimer);
  document.addEventListener("keypress", resetTimer);
  document.body?.querySelector(".App")?.addEventListener("scroll", resetTimer);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/roles" element={<Role />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/createAdmin" element={<CreateAdmin />} />
            <Route path="/accounts/profile" element={<Profile1 />} />
            <Route path="/card/requests" element={<PendingCardRequest />} />
            <Route path="/accountSecurity" element={<AccountSecurity />} />
            <Route path="/users" element={<User />} />
            <Route path="/rms" element={<RMs />} />
            <Route path="/branches" element={<Branches />} />
            <Route path="/accounts/1.0" element={<User1 />} />
            <Route path="/accounts/1.0/profile" element={<Profile />} />
            <Route path="/transactions" element={<AllTransactionsTable />} />
            <Route path="/accounts" element={<QueTable />} />
            <Route path="/accounts/failed" element={<FailedItemsTable />} />
            <Route path="/accounts/pending" element={<AccountPendingTable />} />
            <Route path="/wallet" element={<WalletTable />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/audit" element={<Log />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
