import React from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({required=false, endDate=new Date(), maxDate = new Date(), selected = new Date(), showYearPicker=false, isClearable = false, onChange, className, ...others}) => {
 return (
   <ReactDatePicker
						{...{
       required,
       className: className ? className +" cursor-pointer focus:outline-none" : "w-full py-3 px-3 cursor-pointer text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow",  selected,  endDate, onChange,  maxDate, isClearable, showYearPicker, ...others}} />
 );
}

export default DatePicker;
