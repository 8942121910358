/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./Role.css";
import IPlus from "../../assets/images/plus2.svg";
import Table from "../../components/table/Table";
import HomeLayout from "../../layout/Home";
import RoleAction from "../../actions/roles";
import dayjs from "dayjs";
import RolesModal from "../../components/modal/AddRole";
import Delete from "../../components/modal/Delete";
import ViewPermission from "../../components/modal/ViewPermission";

const Role = () => {
	const [showModal, setModal] = useState(false);
	const [showPermission, setPermission] = useState({state: false, data: {}});
	const [error, setError] = useState("Loading roles...");
	const [isLoading, setIsLoading] = React.useState(false);
	const [permissions, setPermissions] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [deleteRole, setDeleteRole] = useState({ state: false, data: {} });
	const [temp, setTemp] = useState([]);
	const [roles, setRoles] = useState([]);
	const [payload, setPayload] = useState({ name: "", permissions: [] });
	const [data_, setData] = useState([]);

	const handleCreate = (_, payload, refresh) => {
		setDisabled(true);
		RoleAction.createRole(payload)
			.then((res) => {
				setDisabled(false);
				if (res.success) {
					refresh?.();
					setModal(false);
				}
			})
			.catch((e) => {
				setDisabled(false);
			});
	};

	const handleUpdate = (id, payload, refresh) => {
		setDisabled(true);
		const {_data, ...role} = roles.find(d=>d?._data?.id === id)._data;
		
		RoleAction.updateRole(id, payload, undefined, role)
			.then((res) => {
				setDisabled(false);
				if (res.success) {
					refresh?.();
					setModal(false);
				}
			})
			.catch((e) => {
				setDisabled(false);
			});
	};

	const handleDelete = (id, cb) => {
		const {_data, ...role} = roles.find(d=>d?._data?.id === id)._data,
		permissions = role?.permissions?.map(({name}) => name);

		RoleAction.deleteRole(id, permissions)
			.then((res) => {
				cb();
				if (res.success) {
					getRoles();
					setDeleteRole((state) => ({ ...state, state: false }));
				}
			})
			.catch((e) => {
				cb();
				console.error(e);
			});
	};

	const [modalData, setModalData] = useState({
		title: "Create New Role",
		action: handleCreate,
		btnText: "Create Role",
		id: ""
	});

	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		tempData: {},
	});

	const handleSearch = async (e) => {
		setPagination((state) => ({ ...state, page: 1, search: value }));
		setIsLoading(true);
		setTimeout((state) => 3000);
		const { value } = e.target;
		if (value) {
			setPagination((state) => {
				return { ...state, search: value };
			});
		} else {
			setPagination((state) => ({ ...state, search: "" }));
			getRoles();
		}
	};

	async function getRoles() {
		try {
			const { data, success } = await RoleAction.getRoles(pagination);

			if (success) {
				setPagination((state) => {
					return {
						...state,
						total: data.count,
						length: pagination.pageSize * pagination.page,
					};
				});

				const rolesData = data.roles.map((data) => {
					const permissions = data?.permissions
					?.map(({ name }) => name)
					.slice(0, 5),
					count = data.permissions.length;
					count > 5 && permissions.push(` +${count - 5}`) 
					data._data = data;
					return {
						Name: data.name,
						Permissions:
						permissions
								.join(", ") || "N/A",
						"Date Created": dayjs(data.createdAt).format("DD/MM/YYYY"),
						_data: data,
					};
				});

				rolesData?.length === 0
					? setError("No role in the system at the moment")
					: rolesData === (null || undefined)
					? setError("Error loading roles")
					: setRoles(rolesData);
				return rolesData;
			} else setError("Error loading roles");
		} catch (err) {
			setError(err?.message || "Error loading roles");
		}
	}

	useEffect(() => {
		(async () => await getRoles())();
	}, [pagination.page]);

	useEffect(() => {
		RoleAction.getPermissions(pagination)
			.then((res) => {
				if (res.success) {
					setPermissions(res.data.permissions.map(({ name }) => name));
					setTemp(res.data.permissions.map(({ name }) => name));
				}
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		if (showModal === false) {
			setPermissions(temp);
			setData([]);
		}
	}, [showModal]);

	const dropdownMenu = [
		{
			text: "View",
			action: (e, prop) => {
				setPermission(state => ({state: true, data: prop._data}))
			},
		},
		{
			text: "Edit",
			action: (_, data) => {
				setModalData({
					title: "Update Role",
					action: handleUpdate,
					btnText: "Update Role",
					data: data._data,
					id: data._data?.id,
				});

				setModal(true);
				setPayload({
					name: data._data.name,
					permissions: data._data.permissions.map(({ name }) => name),
				});

				setData(data._data.permissions.map(({ name }) => name));
				setPermissions(
					permissions.filter(
						(val) =>
							data._data.permissions.map(({ name }) => name).includes(val) ===
							false,
					),
				);
			},
		},
		{
			text: "Delete",
			action: (e, prop) => {
				setDeleteRole({
					state: true,
					data: {
						action: (cb) => handleDelete(prop._data.id, cb),
						name: prop._data.name,
					},
				});
			},
		},
	];

	return (
		<HomeLayout {...{ showNavigation: true }}>
			<div className="mt-[10%]">
				<div className="flex justify-between">
					<h1 className="font-[500] mb-14 text-[24px] text-[#263238]">
						{" "}
						Role Management{" "}
					</h1>
					<div className="flex">
						<div
							className="flex new_user__btn_div   h-[45px] px-3  cursor-pointer"
							onClick={(e) => setModal((_) => !showModal)}>
							<img src={IPlus} alt="" className="w-5 mr-2" />
							<button
								onClick={(_) => {
									setPayload({ name: "", permissions: [] });
									setData([]);
									setModalData({
										title: "Create New Role",
										action: handleCreate,
										btnText: "Create Role",
									});
								}}
								className="text-[#FF0000]">
								Add new role
							</button>
						</div>
					</div>
				</div>
				<section className="w-full pb-5">
					<div className="table-one rounded-t-[10px] w-[1108px">
						<div className=" h-[50px]  bg-[#F9F9F9] flex justify-between pt-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">
								{" "}
								Roles{" "}
							</h1>
						</div>
						{roles?.length ? (
							<Table
								{...{
									dropdownMenu,
									getRoles,
									data: roles,
									type: Role,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</section>
			</div>

			<RolesModal
				{...{
					showModal,
					setModal,
					data: modalData,
					permissions,
					setPermissions,
					data_,
					setData,
					payload,
					setPayload,
					disabled,
					getRoles,
				}}
			/>

			<Delete
				{...{
					setDeleteModal: (val) =>
						setDeleteRole((state) => ({ ...state, state: val })),
					showDeleteModal: deleteRole.state,
					data: deleteRole.data,
				}}
			/>
			<ViewPermission {...{showModal: showPermission.state, data: showPermission.data, setModal: val => setPermission(state=>({...state, state: val}))}}/>
		</HomeLayout>	
	);
};

export default Role;
