/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UserAction from "../../actions/user";
import Imask from "../../assets/images/mask.svg";
import ModalOne from "../../layout/Modal";
import Button from "../form/button/Button";

const ResetUsers = ({ open, setOpen, data }) => {
  const [disabled, setDisabled] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalOne
      {...{
        showModal: open,
        setModal: setOpen,
        width: "450px",
        height: "250px",
      }}
    >
      <div>
        <div>
          <div className="flex justify-between">
            <h2 className="text-start  py-0 mt-3  font-[700] text-[25px] text-[#000000]">
              Confirm Reset
            </h2>
            <img
              src={Imask}
              alt=""
              className="py-0 mt-5 mr-5 cursor-pointer"
              onClick={handleClose}
            />
          </div>

          <p className="leading-5 text-center mt-5">
            This will send a new password to{" "}
            <span className="capitalize">{data.name}</span>. Are you sure you
            want to proceed with this action?
          </p>

          <div className="flex justify-between ">
            <Button
              {...{
                onClick: handleClose,
                value: "Cancel",
                variant: "dark",
                width: "160px",
                wrapperClass: "mt-10",
                disabled: disabled,
              }}
            />

            <Button
              {...{
                value: disabled ? "Resetting..." : "Reset",
                wrapperClass: "mt-10",
                width: "160px",
                disabled: disabled,
                onClick: () => {
                  setDisabled(true);
                  data.action((_) => setDisabled(false));
                },
              }}
            />
          </div>
        </div>
      </div>
    </ModalOne>
  );
};

export default ResetUsers;
