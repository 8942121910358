/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Input from "../form/inputOne";
import  IUser  from "../../assets/images/user.svg";
import  BACKEND  from "../../utils/backend";




const ForgotPassword = ({ onSubmit, errorMsg, error, setError, disabled, setScreen, setEmail, setIsToken }) => {
    return (
          
          <div
            className="login-form pt-20 w-[500px]"
          >
            <p className="text-start text-lg font-bold mb-14 mt-6 text-[#525252]">
              Change Password
            </p>
            <Input
              name={"email"}
              placeholder={"Email Address"}
              required={true}
              onChange={(e) => {
                setEmail(e.target.value);
                if (error) setError((_) => false);
              }}
              type={"email"}
              icon={IUser}
              inpClass={`${error?'error':''} my-0 pr-2 bg-white`}
                inputClass='pl-2'
            />
            <div className="text-end mb-8">
              <a
                className="inline-block mt-3 align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                href="/"
                onClick={(e) => setScreen("login")}
              >
                Back to login?
              </a>
            </div>
            <button 
            disabled={disabled} 
            className="login-custom-btn w-full" 
            type="submit"
            value={disabled ? "Requesting... " : "Request OTP"}
            >
              Continue
            </button>
          </div>
    );
}

export default ForgotPassword;