/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UserAction from "../../actions/user";
import Imask from "../../assets/images/mask.svg";
import ModalOne from "../../layout/Modal";
import Button from "../form/button/Button";

const Delete = ({ showDeleteModal, setDeleteModal, data }) => {
    
    const [disabled, setDisabled] = React.useState(false);
    
  const handleClose = () => {
    setDeleteModal(false);
  };

  return (
    <ModalOne {...{showModal: showDeleteModal, setModal: setDeleteModal, width: "450px", height: "250px"}}>
      <div>
          <div>
            <div className="flex justify-between">
              <h2 className="text-start  py-0 mt-3  font-[700] text-[25px] text-[#000000]">
                Confirm Delete
              </h2>
              <img
                src={Imask}
                alt=""
                className="py-0 mt-5 mr-5 cursor-pointer"
                onClick={handleClose}
              />
            </div>
          
          <p className="leading-5 text-center mt-5">
            Are you sure you want to delete {data.name}?
          </p>

          <div className="flex justify-between ">
            <Button
            {...{
              onClick: handleClose,
              value: "Cancel",
              variant: "dark",
              width: "160px",
              wrapperClass: "mt-10",
              disabled: disabled,
            }}
            />
              
              <Button
            {...{
              value: disabled ? "Deleting..." : "Delete",
              wrapperClass: "mt-10",
              width: "160px",
              disabled: disabled,
              onClick: () => {
                setDisabled(true)
                data.action(_=> setDisabled(false))
              }
            }}
            />
          </div>
        </div>
        
        </div>
      
    
    </ModalOne>
  )
};

export default Delete;
