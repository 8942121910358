/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import {
//   MdOutlineKeyboardArrowLeft,
//   MdOutlineKeyboardArrowRight,
// } from "react-icons/md";
// import Dropdown from "../Dropdown";
import { RiDeleteBinFill } from "react-icons/ri";
import { GrCheckboxSelected } from "react-icons/gr";
// import Iedit2 from "../../assets/images/edit2.svg";
// import Itrash from "../../assets/images/trash.svg";
import Pagination from "./Pagination";
import Loader from "../loader/Loader";
import Ipp1 from "../../assets/images/pp1.png";

// import {getByStatusText} from "../../utils/color.util";

export default function Table({
  data,
  deleteUser,
  editUser,
  setModal,
  setShowModal,
  getUsers,
  pagination,
  setPagination,
  isLoading = false,
}) {
 
  const [checkedList, setCheckedList] = React.useState([]);

  const check = {
    all: (_) => {
      setCheckedList(data.map((item) => item._id));
      check.checkAll();
    },
    checkAll: (_) => {
      checkedList.forEach((_id) => {
        document.getElementById(_id).checked = true;
      });
    },

    toggleCheck: (res) => {
      if (res) {
        const newCheckedList = [...checkedList];
        if (newCheckedList.includes(res._id)) {
          newCheckedList.splice(newCheckedList.indexOf(res._id), 1);
        } else {
          newCheckedList.push(res._id);
        }
        setCheckedList(newCheckedList);
      } else {
        checkedList.forEach((_id) => {
          document.getElementById(_id).checked = false;
        });
      }
    },
  };

  const handleDelete = (id) => {
    deleteUser(id);
    getUsers();
  };

  const handleEdit = (id) => {
    setModal(true);
    editUser(id);
    setShowModal(true);
  };

  useEffect(() => {
    check.checkAll();
    console.log(checkedList);
  }, [checkedList]);

  const keys = Object.keys(data[0]);
  return (
    <div className="table-container">
      <div
        className="flex items-center mb-2"
        style={{
          visibility: `${checkedList.length > 1 ? "visible" : "hidden"}`,
        }}
      >
        <div
          style={{ background: "var(--C_bg_danger", color: "var(--C_danger)" }}
          className="flex items-center cursor-pointer mr-5 px-2 py-1 rounded"
        >
          <RiDeleteBinFill color="var(--C_danger)" className="shadow-sm mr-2" />
          <span> Bulk Delete</span>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={(_) => {
            check.toggleCheck();
            setCheckedList([]);
          }}
        >
          <GrCheckboxSelected
            color="var(--C_success)"
            className="shadow-sm mr-2"
          />
          <span className="ml-auto">Unselect All</span>
        </div>
      </div>

      <table className="border-collapse w-full table-auto">
        <thead className="border-spacing-y-20">
          <tr className="border-radius-t-5 relative">
            <th className="text-center "></th>
            {keys.map((name, i) => {
              return (
                name !== "_id" && (
                  <th key={i} className="text-start">
                    {name}
                  </th>
                )
              );
            })}
          </tr>
        </thead>
		{!isLoading && 
		   (pagination.total ? (
        <tbody>
          {data.map((res, index) => {
            return (
              <tr className="p-20 mt-5" key={index}>
                <td className="text-center">
                            <div className="p-3">
                              <img
                                src={Ipp1}
                                alt="avatar"
                                className="img-circle bg-[E7F8F3] w-[30px] h-[30px]"
                              />
                            </div>
                          </td>

                {keys.map((name, i) => {
                  return (
                    name !== "_id" && (
                      <td key={i} className="td text-start ">
                        {res[name]}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
        </tbody>
	   ) : (
      ""
	  ))} 
      </table>
      {isLoading && (
				<div className="flex my-5 items-center justify-center w-full">
					<Loader />
				</div>
			)}
      {!pagination.hidden && 
        <>
			
			{!isLoading && !pagination.total && (
				<div className="flex my-5 items-center justify-center w-full">
					<h3 className="text-center">No record found</h3>
				</div>
			)}
			{pagination.total ? (
				<div className={`w-full my-10 ${isLoading ? "visible" : "visible"}`}>
					<div className="flex justify-between items-center">
						<p className="text-sm" style={{ color: "var(--C_blue_light)" }}>
							Showing{" "}
							<span>
								{Math.min(pagination.length, pagination.total) ||
									pagination.pageSize}
							</span>{" "}
							{pagination.total > 1 ? "results" : "result"} of{" "}
							<span>{pagination.total}</span>{" "}
							{pagination.total > 1 ? "records" : "record"}
						</p>

						<Pagination
							{...{
								page: pagination.page-1,
								itemsPerPage: pagination.pageSize,
								setPagination,
								total: pagination.total,
							}}
						/>
					</div>
				</div>
			) : (
				""
			)}
          </>}
          </div>
  );
}
