import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'

const Settings = () => {
  return (
    
    <div>
        <Sidebar />
        <h1 className="text-center">Settings page still under development</h1>

    </div>
  )
}

export default Settings