/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Modal from "../../layout/Modal";
import "./ModalOne.css";
import BACKEND from "../../utils/backend";
import Imask from "../../assets/images/mask.svg";
import SelectField from "../form/SelectField";
import UserActions from "../../actions/user";
import Button from "../form/button/Button";

export default function ModalOne({ showModal, setModal, getUsers, data, options }) {
	const [SolId, setSolId] = useState([]);
	const [userType, setUserType] = useState("");
	const [RegionalManager, setRegionalManager] = useState([]);
	const model = {
		firstName: "",
		lastName: "",
		// middleName: "",
		email: "",
		staffId: "",
		role: "",
		mobile: "",
		branch: "",
		regionalManager: "",
	};
	const [loading, setLoading] = useState(false)

	const getSolId = async () => {
		try {
			const res = await UserActions.getSolId();
			const solIds = res.data.branchCodes.map((data) => data.solDesc);
			setSolId(solIds);
		} catch (error) {
			console.log(error);
		}
	};


	const getRegionalManager = async () => {
		try {
			const res = await UserActions.getRegionalManager();
			const regionalManagers = res.data.regionalmanagers.map(
				(data) => data.accountOfficerName,
			);
			setRegionalManager(regionalManagers);
		} catch (error) {
			console.log(error);
		}
	};

	const [formData, setFormData] = useState(model);
	React.useEffect(() => {
		getSolId();
		getRegionalManager();
	
		if(data?.id){
			setFormData(data.data)
			setUserType(data?.data?.role)
		} else {
			setFormData(model)
		}
	}, []);



	// React.useEffect(() => {
	// 	console.log(data);
	
	// }, [data?.id]);


	const setState = (setFormData, updatedData) => {
		setFormData((data) => {
			return { ...data, ...updatedData };
		});
	};
	const [nameError, setNameError] = useState("");


	const handleNameError = (e) => {
		const value = e.target.value.trim(),
			names = value.split(" ");
		if (value === "") {
			setNameError((_) => "Full Name is required!");
		} else if (names.length !== 3) {
			setNameError((_) => "You must enter your NAME, SURNAME and OTHER NAME!");
		} else if (names.length >= 3) {
			setState(setFormData, {
				firstName: names[0],
				lastName: names[1],
				otherName: names[2],
			});
		}
	};
	function addData(e) {
		let data = {},
			key = e.target.getAttribute("name");
		if (key === "role") {
			setUserType(e.target.value);
		}

		if (key in formData) {
			data[key] = e.target.value;
			setState(setFormData, data);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true)
		UserActions[data.actionName](formData, data?.id)
			.then(async () => {
				// clears	the form
				e.target.reset();
				setState(setFormData, model);
				// closes the modal
				setModal(false);
				setLoading(false)
				// refreshes the users table
				await getUsers();
			})
			.catch((err) => {
				// setModal(false);
				setLoading(false)
			});
	};

	const handleClose = () => {
		setModal(false);
	};


	return (
		<Modal {...{ showModal, setModal, width: "600px", height: "600px" }}>
				<form
					className="modal__one text-left"
					onSubmit={handleSubmit}>
					<div>
						<div className="modal__one__header flex justify-between">
							<h1 style={{fontSize: "18px", fontWeight: 'bolder'}} className="text-start  py-0 mt-4 ml-5 font-bold text-[#000000]">
								{data?.id ? "Update User" : "Create User"}
							</h1>
							<img
								src={Imask}
								alt=""
								className="py-0 mt-5 mr-5 cursor-pointer"
								onClick={handleClose}
							/>
						</div>
						<hr className="border-t-1 border-[#E4E4EB] w-[525px] my-5 m-auto" />
		
		<div>
		<label htmlFor="role" style={{color: "grey"}}>User Role</label>
						<select
						style={{fontSize: '18px'}}
							value={formData.role}
							name="role"
							id="role"
							required
							className="capitalize select_role py-0 px-3 border border-[#D9D8E3] font-[400] text-[#000000] "
							onChange={(e) => {
								setUserType((_) => e.target.value);
								addData(e);
							}}>
								<option selected value="">Select role</option>
								{
									options.map(option => {
								return <option className="capitalize" value={option}>{option}</option>			
									})
								}
						</select>
		</div>
					</div>

							<div className="my-3">
							<label htmlFor="firstName" style={{color: "grey"}}>First Name</label>
					<input
						type="text"
						name="firstName"
						style={{fontSize: '18px'}}
						id="firstName"
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						required
						value={formData.firstName}
					/>
							</div>
			
			<div className="my-3">
				<label htmlFor="lastName" style={{color: "grey"}}>Last Name</label>
				<input
						type="text"
						name="lastName"
						id="lastName"
						style={{fontSize: '18px'}}
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						value={formData.lastName}
						required
					/>
			</div>
			
				{/* <div className="my-3">
					<label htmlFor="middleName" style={{color: "grey"}}>Middle Name</label>
					<input
						type="text"
						style={{fontSize: '18px'}}
						name="middleName"
						id="middleName"
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						value={formData.middleName}
					/>
				</div> */}

					<div className="my-3">
						<label htmlFor="email" style={{color: "grey"}}>Email Address</label>
						<input
						type="email"
						name="email"
						style={{fontSize: '18px'}}
						id="email"
						className="select_role text-[#000000] py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px]  "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						value={formData.email}
						required
					/>
					</div>

					<div className="my-3">
						<label htmlFor="staffId" style={{color: "grey"}}>Staff ID</label>
						<input
						style={{fontSize: '18px'}}
						type="staffId"
						name="staffId"
						id="staffId"
						className="select_role py-0 px-3  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
						onChange={addData}
						onBlur={handleNameError}
						onFocus={(e) => setNameError((_) => "")}
						value={formData.staffId}
						required
					/>
					</div>

							<div className="my-3">
								<label htmlFor="mobile" style={{color: "grey"}}>Phone Number</label>
								<input
								style={{fontSize: '18px'}}
									name="mobile"
									id="mobile"
									type='tel'
									className="select_role py-0 px-3 my-1  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
									onChange={addData}
									value={formData.mobile}
									required
								/>
							</div>

							<div className="my-3">
								<label htmlFor="branch" style={{color: "grey"}}>Branch Code</label>
								<input
								style={{fontSize: '18px'}}
									name="branch"
									id="branch"
									className="select_role py-0 px-3 my-1  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
									onChange={addData}
									value={formData.branch}
									required
								/>
							</div>
					{/* {userType !== "" 
					// && userType?.toLowerCase() !== "admin" 
					&& (
						<div >
								<p className="text-gray-500 font-bold">Additional Information</p>

<div className="my-3">
								<label htmlFor="regionalManager" style={{color: "grey"}}>RM ID</label>
								<input
								style={{fontSize: '18px'}}
									name="regionalManager"
									id="regionalManager"
									className="select_role py-0 px-3 my-1  border border-[#D9D8E3] font-[400] text-[20px] text-[#000000] "
									onChange={addData}
									value={formData.regionalManager}
									required
								/>
							</div>
							
						</div>
					)} */}

					<div className="flex justify-end mt-5 mr-4 mb-8">
            <Button disabled={loading} {...{ 	width: "160px", value: loading? "Processing..." : data?.id ? "Update User" :  "Create User" , wrapperClass: "py-0", type: "submit" }} />
					</div>
				</form>
			
		</Modal>
	);
}
