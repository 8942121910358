/* eslint-disable no-unused-vars */
import config from "../utils/config";
import IWallet from "../assets/images/Union.svg";
import ICard from "../assets/images/houseVector.svg";
import ICards from "../assets/images/card.svg";
import ISettings from "../assets/images/settings.svg";
import IUser from "../assets/images/person_outline.svg";
import IAudit from "../assets/images/audit.png";
import IDashboard from "../assets/images/dashboard.svg"
import { AiOutlineAudit } from "react-icons/ai";
import { permissions, Roles } from "./helper";

const {
  pageUrls
} = config,
{ admin, superadmin, agent, branchManager } = Roles,

{ viewDashboard, getLogs, getRoles, getCardRequests, getAccounts, getUsers, getWallet, viewSettings } = permissions;


const links = [{
  auth: [viewDashboard],
    name: "Dashboard",
    url: pageUrls.dashboard,
    icon: IDashboard ,
    xtra: {
      icon: "",
      data: null
    }
  },
  {
    auth: [getUsers],
    name: "User Management",
    url: null,
    icon: IUser,
    xtra: {
      data: [ {name:"Roles", url: '/roles', auth: getRoles}, {name:"Users", url: '/users', auth: getUsers}, {name:"RMs", url: '/rms'},
      {name:"Branches", url: '/branches'} 
     ],        
    } 
  },
  {
    auth: [getWallet],
    name: "Wallets",
    url: pageUrls.wallet,
    icon: IWallet ,
    xtra: {
      data: null
    }
  },
  {
    auth: [getAccounts],
    name: "Accounts",
    url: pageUrls.accounts,
    icon: ICard ,
    xtra: {
      data: null
    }
  },
  {
    auth: [getAccounts],
    name: "Accounts 1.0",
    url: pageUrls.accountsv1,
    icon: ICard ,
    xtra: {
      data: null
    }
  },
  {
    auth: [getCardRequests],
    name: "Card Requests",
    url: '/card/requests',
    icon: ICards ,
    xtra: {
      data: null
    }
  },
  {
    auth: [viewSettings],
    name: "Settings",
    url: pageUrls.settings,
    icon: ISettings ,
    xtra: {
      data: null
    }
  },
  {
    auth: [getLogs],
    name: "Audits",
    url: pageUrls.audit,
    icon: IWallet ,
    xtra: {
      data: null
    }
  },
  
]


export default links;