/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import "../../components/dashboard/Dashboard.css";
import "../role/Role.css";
import TrasactionHeader from "../../components/dashboard/components/TrasactionHeader";
import Table from "../../components/table/Table3";
import { useNavigate } from "react-router-dom";
import ISearch from "../../assets/images/search.svg";
import Inotifications from "../../assets/images/notifications.svg";
import Iback from "../../assets/images/backArrow.svg";
import Iellipse from "../../assets/images/Ellipse.svg";
import Idown from "../../assets/images/down.svg";
import Dropdown from "../../components/others/Dropdown";
import HomeLayout from "../../layout/Home";
const AllTransactionsTable = () => {
	const [showModal, setModal] = useState(false);
	const [allTransaction, setAllTransaction] = useState([]);
	const [error, setError] = useState("Loading users...");
	const [isLoading, setIsLoading] = React.useState(false);
	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 4,
		total: 0,
		tempData: {},
	});
	const [show, setShow] = React.useState(false);
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user"));

	const allTransactions = [
		{
			_id: "1",
			"Transaction Name": "Bank Transfer",
			recipient: "Brian knight",
			Amount: "NGN3,000",
			"Time & Date": "12:15:52, 11-03-2021",
			status: "Failed",
		},
		{
			_id: "2",
			"Transaction Name": "USSD Transfer",
			recipient: "Jane Bassey",
			Amount: "NGN90,000",
			"Time & Date": "09:15:52, 11-03-2021",
			status: "Successful",
		},
		{
			_id: "3",
			"Transaction Name": "Bank Transfer",
			recipient: "Grace Oluyemi",
			Amount: "NGN45,000",
			"Time & Date": "11:15:52, 11-03-2021",
			status: "Failed",
		},
		{
			_id: "4",
			"Transaction Name": "USSD Transfer",
			recipient: "Franklin Obunna",
			Amount: "NGN200,000",
			"Time & Date": "10:15:52, 11-03-2021",
			status: "Successful",
		},
	];

	async function getAllTransactions() {
		try {
			const { data, success } = { data: { count: 4 }, success: true };
			if (success) {
				setPagination((state) => {
					return { ...state, total: data.count };
				});
				const tmpAllTransaction = [];
				const allTransactionsData = allTransactions.map((data) => {
					tmpAllTransaction.push(data.name);
					data._data = data;
					return data;
				});
				setAllTransaction(allTransactionsData);
				allTransactionsData?.length === 0
					? setError("No pending accounts found")
					: allTransactionsData === (null || undefined)
					? setError("No pending accounts found")
					: setAllTransaction(allTransactionsData);
				return allTransactionsData;
			} else setError("No pending accounts found");
		} catch (error) {
			setError(error?.message || "No pending accounts found");
		}
	}

	const handleSearch = async (e) => {
		setPagination((state) => ({ ...state, page: 1, search: value }));
		setIsLoading(true);
		setTimeout((state) => 3000);
		const { value } = e.target;
		if (value) {
			setPagination((state) => {
				return { ...state, search: value };
			});
		} else {
			setPagination((state) => ({ ...state, search: "" }));
			AllTransactionsTable();
		}
	};

	useEffect(() => {
		(async () => await getAllTransactions())();
	}, [pagination.page]);

	return (
		<HomeLayout {...{ useSearch: true, showNavigation: true }}>
			<div className="mt-[10%]">
				<div className="flex justify-between">
					<h1 className="font-[500] mb-14 text-[24px] text-[#263238]">
						Transactions
					</h1>
				</div>

				<div className="text-start ">
					<div className="table-one rounded-t-[10px] w-[1108px">
						<div className=" h-[50px]  bg-[#F9F9F9] flex justify-between pt-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">
								Recent Transactions
							</h1>
						</div>
						{allTransaction?.length ? (
							<Table
								data={allTransactions}
								{...{
									getAllTransactions,
									data: allTransactions,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</div>
			</div>
		</HomeLayout>
	);
};

export default AllTransactionsTable;
