/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useState } from 'react';
import CustomLayout from '../../components/auth/CustomLayout';
import SignIn from '../../components/auth/SignIn';
import ChangePassword from '../../components/auth/ChangePassword';
import ForgotPassword from '../../components/auth/ForgotPassword';
import OTP from '../../components/auth/Otp';
import UserAction from "../../actions/user";
import Token from '../../components/auth/Token';

function Login() {
    const [email, setEmail] = useState("");
    const [screen, setScreen] = useState("login");
    const [msg, setMsg] = useState("");
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false)

    

    function getScreen() {
        switch (screen) {
            case "login":
                return(
                <CustomLayout
                    onSubmit={(e) => 
                        UserAction.login(e, {setScreen, setMsg, setError, setDisabled})
                    } 
                    errorMsg={error}
                    
                    component={<SignIn {...{disabled, email, setError, setEmail, error, setMsg, setScreen, setDisabled}} />}
                />
                );
                case "new-user":
                return(
                    <CustomLayout
                       errorMsg={error}
                       onSubmit={(e) =>
                            UserAction.changePassword(e, {setScreen, setDisabled, setError})
                       }
                       component={<ChangePassword {...{ email, setError, error, setScreen }} 
                       screenText={"Change your default password to complete login"}
                       />
                    }
                />
                );
                case "OTP":
                return(
                    <CustomLayout
                          errorMsg={error}
                            onSubmit={(e) =>
                                UserAction.verifyToken(e, {email, setDisabled, setScreen, setError})
                            }
                            component={<OTP {...{disabled,  email, setScreen, setError, error, msg}}
                            screenText={"Enter the OTP sent to your email"}
                            />
                        }
                    />
                );
                case "token": 
                return (
                    <CustomLayout
                    onSubmit={(e) => {  
                            
                            const cb = (_) => {
                              setScreen("new-user");
                            };
                            UserAction.resetPassword(e,
                              { email, token: e.target.token.value },
                              cb
                            );
                          }
                    }
                            errorMsg={error}
                            component={<Token {...{ setScreen, email }} />}
                        />
                )
                case "forgot-password":
                return(
                    <CustomLayout
                            onSubmit={(e) =>
                                UserAction.forgotPassword(e, {email, setDisabled, setScreen, setError })
                            }
                            errorMsg={error}
                            component={
                                <ForgotPassword {...{setScreen, disabled, error, setError, setEmail}}
                            screenText={"Enter your email to reset your password"}
                            />
                        }
                    />
                );
            default:
                return <h1>Wrong page rendered, please reload the page</h1>;
		}
    }
    return getScreen();
}

export default Login;