/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../Dropdown";
import { RiDeleteBinFill } from "react-icons/ri";
import { GrCheckboxSelected } from "react-icons/gr";
import Pagination from "./Pagination";
import Loader from "../loader/Loader";

// import {getByStatusText} from "../../utils/color.util";

export default function Table({
  data,
  dropdownMenu,
  colorizeStatus,
  deleteUser,
  editUser,
  setModal,
  setShowModal,
  showModal,
  getUsers,
  pagination,
  setPagination,
  isLoading = false,
  _clickedRowData
}) {
  const [selected, setSelected] = useState([]);
  const [setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 15, 20]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [checkedList, setCheckedList] = React.useState([]);
  // allInputChecker = useRef(false);

  const check = {
    all: (_) => {
      setCheckedList(data.map((item) => item._id));
      check.checkAll();
    },
    checkAll: (_) => {
      checkedList.forEach((_id) => {
        document.getElementById(_id).checked = true;
      });
    },

    toggleCheck: (res) => {
      if (res) {
        const newCheckedList = [...checkedList];
        if (newCheckedList.includes(res._id)) {
          newCheckedList.splice(newCheckedList.indexOf(res._id), 1);
        } else {
          newCheckedList.push(res._id);
        }
        setCheckedList(newCheckedList);
      } else {
        checkedList.forEach((_id) => {
          document.getElementById(_id).checked = false;
        });
      }
    },
  };

  const handleDelete = (id) => {
    deleteUser(id);
    getUsers();
  };

  const handleEdit = (id) => {
    setModal(true);
    editUser(id);
    setShowModal(true);
  };

  //create handler to edit rows of content in the table
  const editRows = (res) => {
    const newData = [...data];
    newData.splice(
      newData.findIndex((item) => item._id === res._id),
      1,
      res
    );
    return newData;
  };

  useEffect(() => {
    check.checkAll();
    console.log(checkedList);
  }, [checkedList]);

  const keys = Object.keys(data[0]);
  return (
    <div className="table-container">
      <div
        className="flex items-center mb-2"
        style={{
          visibility: `${checkedList.length > 1 ? "visible" : "hidden"}`,
        }}
      >
        <div
          style={{ background: "var(--C_bg_danger", color: "var(--C_danger)" }}
          className="flex items-center cursor-pointer mr-5 px-2 py-1 rounded"
        >
          <RiDeleteBinFill color="var(--C_danger)" className="shadow-sm mr-2" />
          <span> Bulk Delete</span>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={(_) => {
            check.toggleCheck();
            setCheckedList([]);
          }}
        >
          <GrCheckboxSelected
            color="var(--C_success)"
            className="shadow-sm mr-2"
          />
          <span className="ml-auto">Unselect All</span>
        </div>
      </div>

      <table className="border-collapse w-full table-auto">
        <thead className="border-spacing-y-20">
          <tr className="border-radius-t-5 relative">
            <th className="text-center "></th>
            {keys.map((name, i) => {
              return (
                name !== "_id" && (
                  <th key={i} className="text-start">
                    {name}
                  </th>
                )
              );
            })}
            {/* {deleteUser && <th>Action</th>} */}
          </tr>
        </thead>
		{!isLoading && 
		   (pagination ? (
        <tbody >
          {data.map((res, index) => {
            return (
              <tr className="p-20 mt-5 " key={index}>
                <td className="text-center ">
                            <div className="m-2 pt-3 pl-2 bg-[#E7F8F3] rounded-[5px] w-[37px] h-[37px]">
                              {/* <img
                                src={Isend}
                                alt="avatar"
                                className="img-circle  w-[20px] h-[20px] bg-[E7F8F3]"
                              /> */}
                            </div>
                          </td>

                {keys.map((name, i) => {
					// const _clickable = 
					//   res._clickable && res._clickable === res[name];
					// const val = getByStatusText(res[name], colorizeStatus);
                  return (
                    name !== "_id" && (
                      <td key={i} className="td text-start ">
                        {res[name]}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
        </tbody>
	   ) : (
		""
	  ))} 
      </table>
      {isLoading && (
				<div className="flex my-5 items-center justify-center w-full">
					<Loader />
				</div>
			)}
			{!isLoading && !pagination.total && (
				<div className="flex my-5 items-center justify-center w-full">
					<h3 className="text-center">No record found</h3>
				</div>
			)}
			{pagination.total?<div className={`w-full my-10 ${isLoading ? "visible" : "visible"}`}>
				<div className="flex justify-between items-center">
					<p className="text-sm ml-3" style={{ color: "var(--C_blue_light)" }}>
						Showing{" "}
						<span>
							{Math.min(pagination.length, pagination.total) ||
								pagination.pageSize}
						</span>{" "}
						results of <span>{pagination.total}</span> records
					</p>

					<Pagination
						{...{
							itemsPerPage: pagination.pageSize,
							setPagination,
							total: pagination.total,
						}}
					/>
				</div>
			</div>:''}
    </div>
  );
}
