import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import TransactionHeader from "../components/dashboard/components/TrasactionHeader";
import Storage from "../utils/storage";

const HomeLayout = ({ children, useSearch, showNavigation, handleSearch, searchPlaceholder }) => {
	let user = Storage.getUser();
    
	return (
		<div className="sidebar-wrapper ml-16">
			<Sidebar />
			<div className="Main__page">
				<main className="dashboard__page">
					<div style={{ width: "75%", marginLeft: "20%"}}>
					<TransactionHeader {...{ user, useSearch, showNavigation, handleSearch, searchPlaceholder}} />
						{" "}
						{children}{" "}
					</div>
				</main>
			</div>
		</div>
	);
};

export default HomeLayout;
