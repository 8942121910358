import React, { useState} from "react";
import Sidebar from "./sidebar/Sidebar";
import Storage from "../utils/storage";
import BACKEND from "../utils/backend";


const CreateAdmin = () => {

const [userType, setUserType] = useState("");

let user = Storage.getUser();

    const model = {
      firstName: "",
      lastName: "",
      otherName: "",
      email: "",
      staffId: "",
      role: user?.role ==="superadmin"? "admin":"",
      virtualAccount: "",
      branch: ""
    };

    const setState = (setFormData, updatedData) => {
      setFormData((data) => {
        return { ...data, ...updatedData };
      });
    };
    // const [error, setError] = useState({ email: "", pass: "" });
    const [formData, setFormData] = useState(model);

    function addData(e) {
      let data = {},
        key = e.target.getAttribute("name");
        if(key==='role'){
          setUserType(e.target.value)
        }
      if (key in formData) {
        data[key] = e.target.value;
        setState(setFormData, data);
      } else throw new Error("Invalid input key name in form!");
    }    

    const handleSubmit = (e) => {
        e.preventDefault();

        const cb = e => {
          const data = {
            firstName: "",
            lastName: "",
            otherName: "",
            email: "",
            staffId: "",
            role: user?.role ==="superadmin"? "admin":"",
            virtualAccount: "",
            branch: ""
          };
          setState(setFormData, data);
        }
        new BACKEND().createUser(formData, cb)
    }



    function showHtml(user) {
      let output = null;
      if(user === "superadmin") {
      output = (
        <>
          <option value="">Admin</option>
        </>
      )
      }
      else if(user === "admin") {
        output = (
          <>
            <option value="">Select Role</option>
            <option value="agent">Agent</option>
            <option value="bm">Branch Manager (BM)</option>
          </>
        )
        }
        return output
   }

  return (
    
    <div className="grid grid-cols-3 gap-4 md:auto-rows-min">
      <div className="col-span-6 static">
      <Sidebar />
      </div>
      <div className="col-span-8 flex flex-row  items-center justify-center h-screen py-40">
        <div className="w-full max-w-xs mt-40">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={e=>handleSubmit(e)}>
          
                <div className="mb-6">
                <h1 className="text-center text-3xl font-bold mb-6 mt-">
                Create New User
            </h1>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                        First Name
                    </label>
                    <input
                        className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="firstName"
                        name="firstName"
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={addData}
              />

              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Last Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                id="username"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={addData}
              />
             
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Other Names
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                id="username"
                type="text"
                placeholder="Other Names"
                name="otherName"
                value={formData.otherName}
                onChange={addData}
              />
             
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={addData}
              />
              {/* <p className="text-red-500 text-xs italic">
                Please choose a unique and valid email.
              </p> */}

              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Staff ID
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="id"
                type="id"
                placeholder="Staff Id"
                name="staffId"
                value={formData.staffId}
                onChange={addData}
              />
              <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                  >
                    User Role
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                    id="admin"
                    name="role"
                    value={formData.role}
                    onChange={addData}
                >
                    {showHtml(user?.role)
                    }
                    
                </select> 

              {/* <p className="text-red-500 text-xs italic">
                Please choose a unique and valid email.
              </p>  */}
              {(user?.role === "admin" && (userType==="agent" || userType==="bm")) &&
                <>
                  <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                  >
                    Branch
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="id"
                    type="id"
                    placeholder="Branch"
                    name="branch"
                    onChange={addData}
                  />

                  <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                  >
                    Virtual Account
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="id"
                    type="id"
                    placeholder="Virtual Account"
                    name="virtualAccount"
                    onChange={addData}
                  />
                </>
              }
              <div className="flex items-center justify-between">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold w-full py-2 px-4 mb-8 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  Create User
                </button>
                </div>  
            </div>
          </form>
        </div>
        </div>
      </div>
        
    );
}

export default CreateAdmin;
