/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import UserAction from "./../../actions/user";
import Table from "../../components/table/Table";
import { Statuses } from "../../utils/helper";
import HomeLayout from "../../layout/Home";
import Select from "../../components/form/Select";
import DatePicker from "../../components/form/DatePicker/DatePicker";
import dayjs from "dayjs";
import Avatar from "../../assets/images/avatar.png"
import { useNavigate } from "react-router-dom";
import { FiDownloadCloud } from "react-icons/fi";
import config from "../../utils/config";

const QueTable = () => {
	const [accounts, setAccounts] = useState([]);
	const [showModal, setModal] = useState(false);
	const [error, setError] = useState("Loading accounts...");
	const [isLoading, setIsLoading] = React.useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(null);

	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		length: 0,
		role: "",
		search: "",
		by: "",
		status: "",
		date: { start: new Date(), end: new Date() },
	});

	const dlUrl = () => {
		let url = config.backendUrl.endsWith("/")
		  ? config.backendUrl + "accounts/downloadaccountsbydate"
		  : config.backendUrl + "/accounts/downloadaccountsbydate";
		console.log(pagination)
		return `${url}/?startDate=${pagination.date.start}&endDate=${pagination.date.end}`;
	  };

	const onChange = async (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		if (start && end) {
			setPagination((state) => {
				return {
					...state,
					by: "date",
					date: {
						start: dayjs(start).format("YYYY-MM-DD"),
						end: dayjs(end).format("YYYY-MM-DD"),
					},
					page: 1,
				};
			});
		}
	};

	async function getAccounts  (actionName = "getAccounts")  {
		try {
			const accounts = await UserAction[actionName](pagination);
			function loadAccounts({ data }) {
				setIsLoading(false);
				setPagination((state) => {
					return {
						...state,
						total: data?.count,
						length: pagination.pageSize * pagination.page,
					};
				});

				const accountData = data?.accounts?.map(
					(d) => {
						const { name, type, createdAt, accountNumber, status, id, passport } = d;
						return {
							_id: id,
							"": (passport?.startsWith("file") || passport === null) ? Avatar : `data:image/png;base64,${passport}`,
							"Account Name": name,
							"Account Type": type,
							"Account Number": accountNumber,
							created: createdAt.slice(0, 10),
							status: status,
							_data: d
						};
					},
				);
				accountData.length === 0
					? setError("No account in the system at the moment")
					: accountData.length === (null || undefined)
					? setError("Error loading accounts")
					: setAccounts(accountData);

				return accountData;
			}
			loadAccounts(accounts);
		} catch (error) {
			setError("Error loading accounts");
		}
	};

	const [timeOut, setTimeOut] = useState(3000);
	
	useEffect(() => {
		setIsLoading(true);
		let actionName = "getAccounts";
		if(pagination.by === 'date') 	actionName = 'filterAccountsByDate';
		(async () => {
			await getAccounts(actionName);
		})();
	}, [pagination.status, pagination.date.end, pagination.page]);

	useEffect(() => {
		if (timeOut === 0) {
			getAccounts();
		}
	}, [timeOut]);

	const handleTimeOut = () => {
		setTimeOut((state) => (state - 50 <= 0 ? 0 : state - 50));
	};
	if (pagination.search) {
		setInterval(handleTimeOut, 100);
	} else clearInterval(handleTimeOut);

	const handleSearch = async (e) => {
		setPagination((state) => ({ ...state, page: 1, search: value }));
		setIsLoading(true);
		setTimeOut((state) => 3000);
		const { value } = e.target;
		if (value) {
			setPagination((state) => {
				return { ...state, search: value };
			});
		} else {
			setPagination((state) => ({ ...state, search: "" }));
			getAccounts();
		}
	};

	let options = [
		{
			name: "All",
			value: "",
		},
		{
			name: "Successful",
			value: Statuses.successful,
		},
		{
			name: "Pending",
			value: Statuses.pending,
		},
		{
			name: "Failed",
			value: Statuses.failed,
		},
	];

	const navigate = useNavigate();


	const dropdownMenu = [
		{
			text: "View Profile",
			action: (e, data) => {

    navigate('/accounts/profile', {state: data._data})
			},
		},
	]

	return (
		<HomeLayout {...{ useSearch: true, showNavigation: true, handleSearch }}>
			<div className="mt-[10%]">
				<div className="flex flex-col">
					<h1 className="font-[500] text-left mb-1 text-[24px] text-[#263238]">
						Accounts
					</h1>

					<div className="flex">
						<p className="mt-5 mr-5 w-20">Sort by</p>
						<Select
							name={"role"}
							height="40px"
							selectClass={"py-2 bg-white w-32 mr-5 shadow block rounded-lg"}
							onChange={({ target: { value } }) => {
								setPagination((state) => ({ ...state, status: value,  by: "status", page: 1,}));
							}}
							options={options}
						/>
						<p className="mt-5 mr-2">Date</p>
						<DatePicker
							className="px-3 left-4 top-[-10px] py-2  text-center shadow rounded-lg absolute"
							selected={endDate}
							onChange={onChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							isClearable={false}
						/>
					</div>
				</div>
				<div className="text-start ">
					<div className="table-one rounded-t-[10px] w-[1108px">
						<div className="  bg-[#F9F9F9] flex justify-between  py-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">
								Account Created
							</h1>

							<div className="flex">
								<a
								className={`flex items-center mr-5 h-[45px] px-3 bg-[#000] text-white rounded-md decoration-none ${isLoading ? 'hover:bg-gray-800' : 'cursor-pointer bg-white'}`}
								style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
								href={isLoading ? '#' : dlUrl()}
								>
								<FiDownloadCloud size={20} className="mr-2" />
								<span>Download Accounts</span>
								</a>
							</div>
						</div>

						{accounts.length ? (
							<Table
								{...{
									dropdownMenu,
									getAccounts,
									data: accounts,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</div>
			</div>
		</HomeLayout>
	);
};

export default QueTable;
