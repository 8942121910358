/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../pages/user/User.css";
import "./Dashboard.css";
import TransactionHistory from "./components/TransactionHistory";
import TotalRegistered from "./components/TotalRegistered";
import RegisteredToday from "./components/RegisteredToday";
import AccountsPending from "./components/AccountsPending";
import FailedItems from "./components/FailedItems";
import RecentTransactionsTable from "../table/RecentTransactionsTable";
import AccountInQueTable from "../table/AccountInQueTable";
import HomeLayout from "../../layout/Home";

const Dashboard = () => {
	const [totalAccounts, setTotalAccounts] = React.useState(0);

	return (
		<HomeLayout>
			<div className="mt-[10%] mb-10">
				Welcome to UINSTA!
				<div className="flex w-full justify-between flex-wrap">
					{/* <TransactionHistory /> */}
					<TotalRegistered {...{ totalAccounts }} />
					<RegisteredToday />
						<FailedItems />
				</div>
				<div className="mt-10 mr-3 w-full">
					{/* <RecentTransactionsTable /> */}
					<div className="w-full mt-10">
						<AccountInQueTable {...{ setTotalAccounts }} />
						{/* <AccountsPending /> */}
					</div>
				</div>
			</div>
		</HomeLayout>
	);
};

export default Dashboard;
