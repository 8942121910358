import React, { useRef, useState } from "react";
import { getFileSize } from "../../utils/helper";
import Modal from "../../layout/Modal";
import Button from "../form/button/Button";
import Alert from "../../utils/alert";
import UserAction from "../../actions/user";
import { GrClose } from "react-icons/gr";
import IExcel  from "../../assets/images/excel.svg";

export default function UploadUsers({ showModal, setModal, getUsers, action = 'uploadUsers', title = 'Users'}) {
	const fileUploadDiv = useRef(null);
	const [image, setImage] = useState({
		src: "",
		file: null,
		size: 0,
		type: "",
		name: "",
	});
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (e) => {
		
		const formData = new FormData();
		formData.append("file", image.file);

		setDisabled(true);
		await UserAction[action]({
			payload: formData,
			setDisabled,
			cb: (_) => {
				setDisabled(false);
				getUsers();
				setImage({
					src: "",
					file: null,
					size: 0,
					type: "",
					name: "",
				})
				setModal(false);
			},
		});
	};

	const dragDrop = (e) => {
			e.preventDefault();
			fileUploadDiv?.current?.classList.remove("drag_active");
			const files = e?.dataTransfer?.files || e?.target?.files;

			if (files.length > 0) {
				const file = files[0];
				const ext = file.name.split(".")[1].toLowerCase();
				if (ext === "xlsx" || ext === "ods") {
					setImage((state) => {
						return {
							...state,
							src: URL.createObjectURL(file),
							size: getFileSize({ size: file?.size }),
							file,
							type: file.type,
							name: file.name,
						};
					});
				} else {
					Alert({
						type: "error",
						message: "Only excel file is allowed for upload",
					});
				}
			}
		};

	return (
		<Modal {...{ showModal, setModal, width: "600px", height: "480px" }}>
			<div className="flex justify-end w-full">
				<Button
					onClick={(_) => {
						setImage({
							src: "",
							file: null,
							size: 0,
							type: "",
							name: "",
						})
						setModal(!showModal)
					}}
					variant={"secondary"}
					wrapperClass={"px-1 py-0"}
					value={
						<GrClose
							size={"20px"}
							className="font-bold mx-2 my-0 py-0 text-white"
						/>
					}
				/>
			</div>
			<div className="flex items-center justify-center w-full">
				<div className="flex items-center flex-col h-full justify-center w-1/2">
					<h1 className="text-center mb-5">Upload {title}</h1>
					<div className="w-full mb-2">

						<div
							className={`flex items-around justify-between w-full mt-2 ${
								image.type ? "visible" : "invisible"
							}`}>
							<p className="text-sm text-slate-900">
								<b>Name:</b> {image?.name}
							</p>
						</div>
					</div>

					<div className=" border border-slate-900 w-full h-52">
						<div
							className="relative h-full  border  text-base cursor-pointer text-feint rounded"
							ref={fileUploadDiv}
							>
							<div className="flex flex-col items-center justify-center h-full w-full">
								{image.type ? (
									<>
										<img src={IExcel} alt="" style={{ height: "70%" }} />
										<p className="text-sm text-slate-900">{image.size}</p>
									</>
								) : (
									<div className="text-center text-slate-300 mb-2 ">
										<p>
											Drag{" "}
											<span style={{ color: "var(--C_primary)" }}>excel</span>{" "}
											file here
										</p>
										<p className="mt-2">or</p>
										<p
											className="underline underline-offset-4 text-center"
											style={{ color: "var(--C_primary)" }}>
											Select File
										</p>
									</div>
								)}
							</div>
							<input
								onDrop={dragDrop}
								onChange={dragDrop}
								type="file"
								className="w-full border-dotted border-slate-400 h-full absolute border  top-0  cursor-pointer z-[100] left-0 opacity-0"
							/>
						</div>
					</div>
					<div className="w-full my-2">
						<Button	
							value={disabled ? "Uploading..." : "Upload File"}
							disabled={!image.type}
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
}
