/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
import React, { useEffect, useState } from "react";
import "./User.css";
import IPlus from "../../assets/images/plus2.svg";
import Table from "../../components/table/Table";
import Modal from "../../components/modal/ModalOne";
import UserAction from "../../actions/user";
import ISearch from "../../assets/images/search.svg";
import { FiSearch, FiUploadCloud } from "react-icons/fi";
import UploadUsers from "../../components/modal/UploadUsers";
import HomeLayout from "../../layout/Home";
import Delete from "../../components/modal/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const User1 = () => {
	const [showModal, setModal] = useState(false);
	const [accounts, setUsers] = useState([]);
	const [error, setError] = useState("Loading accounts...");
	const [modals, setModals] = useState({ upload: false });
	const [isLoading, setIsLoading] = React.useState(false);
  const [deleteUser, setDeleteUser] = useState({state: false, data: {}})
	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		length: 0,
		role: "",
		search: "",
	});

const navigate = useNavigate();

	const [modalData, setModalData] = React.useState({
		actionName: "createUser",
	});

	const getUsers = async () => {
		setIsLoading(true);
		try {
			let accounts = await axios.get(`https://uinstaprodapi.ubagroup.com/seed/uinstaonedata/?search=${pagination.search||''}&pageNumber=${pagination.page}&pageSize=${pagination.pageSize}`, {headers: {'ngrok-skip-browser-warning': true}})
   

	function loadUsers({data}) {
		setIsLoading(false);
		setPagination((state) => {
			return {
				...state,
				total: data?.count,
				length: pagination.pageSize * pagination.page,
			};
		});

		const userData = data?.data?.map((res) => {
     const {text} = res;
     let data = {__data: res}
     for(let t in text) {
      switch(text[t].label.toLowerCase()) {
       case 'bvn': data.BVN = text[t].value;
       break;
       case 'middle name': data['Middle Name'] = text[t].value || 'N/A';
       break;
       case 'first name': data['First Name'] = text[t].value || 'N/A';
       break;
       case 'last name': data['Last Name'] = text[t].value || 'N/A';
       break;
       case 'scheme description': data['Scheme Description'] = text[t].value || 'N/A';
       break;
       case 'gender': data['gender'] = text[t].value || 'N/A';
       break;
       
       default: data = data;
      }
     }

					return data
				});

				userData?.length === 0
					? setError("No account found")
					: userData === (null || undefined)
					? setError("Error loading accounts")
					: setUsers(userData);
				return userData;
			}
   
			loadUsers(accounts.data);
		} catch (error) {
			console.log(error);
			setError("Error loading accounts");
		}
	};

	useEffect(() => {
		(async () => {
			if(ready) await getUsers();
		})();
	}, [pagination.page]);

	const submitSearch = async () => {
		setIsLoading(true)
		setReady(true)
		getUsers()
	};


	//delete user by uuid and refresh the page
	const handleDelete = async (uuid, cb) => {
		try {
      let res = await UserAction.deleteUser(uuid);
      cb()
			if(res.success) {
        setPagination(state=> ({...state, search: ""}))
        getUsers()
        setDeleteUser((state) => ({ ...state, state: false }));
      }
		} catch (error) {
			setError(error.response.data.message);
			setIsLoading(false);
		}
	};

	//update user by uuid and refresh the page
	const handleEdit = async (userId) => {
		try {
			await UserAction.updateUser(userId);
			setIsLoading(true);
			getUsers();
		} catch (error) {
			setError(error.response.data.message);
			setIsLoading(false);
		}
	};

	const dropdownMenu = [
		{
			text: "View Profile",
			action: (e, data) => {
    navigate('/accounts/1.0/profile', {state: data})
			},
		},
	];
	const [ready, setReady] = useState(false)

	return (
		<HomeLayout {...{ useSearch: false, showNavigation: true }}>
			<div className="mt-[10%] overflow-hidden">
				<div className="flex justify-between  items-center mb-14">
					<h1 className="font-[500] text-[24px] text-[#263238]">
						Accounts (v1.0)
					</h1>
					
					{
						ready && <FiSearch size={30} className='cursor-pointer' onClick={(_) => setReady(false)} />
					}
				</div>

				<section className="w-full pb-5 flex justify-center overflow-hidden">
					{ready ? <div className="table-one rounded-t-[10px] w-full">
						<div className=" h-[50px]  bg-[#F9F9F9] text-start pt-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">Accounts</h1>
						</div>
						{accounts.length ? (
							<Table
								{...{
									dropdownMenu,
									handleEdit,
									getUsers,
									setError,
									data: accounts,
									handleDelete,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div> :
					<div 
					style={{fontFamily: "Roboto" }}
					className="flex border-slate-500 px-2 border-2 h-[60px] w-1/2 mt-6 rounded-2xl	">
					<input
							type="text"
							id="search"
							value={pagination.search}
							onChange={e=>{
								const {value} = e.target;
								// if(!isNaN(value)) {
									setPagination(state=>({...state, search: value}))
								// }
							}}
							onKeyUp={e=>{
								if(e.key === 'Enter') submitSearch()
								
							}}
							placeholder="Search by NAME or BVN"
							maxLength='11'
							className='w-full py-2 m-0 text-lg h-[55px] focus:outline-none rounded font-normal'
					/>
					<label htmlFor="search" className="block border-2 border-black">
						<FiSearch size={35} color='grey' className="mt-1 cursor-pointer" onClick={submitSearch}/>
					</label>
			</div>}
				</section>
			</div>

			{showModal && (
				<Modal
					getUsers={getUsers}
					showModal={showModal}
					setModal={setModal}
					data={modalData}
				/>
			)}
			<UploadUsers
				{...{
					getUsers,
					showModal: modals.upload,
					setModal: (val) => setModals((state) => ({ ...state, upload: val })),
				}}
			/>

<Delete
				{...{
					setDeleteModal: (val) =>
          setDeleteUser((state) => ({ ...state, state: val })),
					showDeleteModal: deleteUser.state,
					data: deleteUser.data,
				}}
			/>
		</HomeLayout>
	);
};

export default User1;
