/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UserAction from "./../../actions/user";
import Table from "../../components/table/Table";
import HomeLayout from "../../layout/Home";
import Select from "../../components/form/Select";
import Role from "../../actions/roles";

const Log = () => {
	const [audits, setAudits] = useState([]);
	const [showModal, setModal] = useState(false);
	const [error, setError] = useState("Loading audits...");
	const [isLoading, setIsLoading] = React.useState(false);
 const [role, setRole] = useState([])

	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 10,
		total: 0,
		length: 0,
		role: "",
		search: "",
	});

	const getLogs = async () => {
		try {
			const audits = await UserAction.getLogs(pagination);
			function loadAudits({ data }) {
				setIsLoading(false);
				setPagination((state) => {
					return {
						...state,
						total: data?.count,
						length: pagination.pageSize * pagination.page,
					};
				});

				const auditData = data?.logs.map(
					({ user, id, role, ipAddress, location, event, createdAt }) => {
						return {
							_id: id,
							name: user.firstName + " " + user.lastName,
							// email: user.email,
							role,
							event,
							"IP Address": ipAddress,
							location: location || "N/A",
							created: createdAt.slice(0, 10),
						};
					},
				);
				auditData.length === 0
					? setError("No audit in the system at the moment")
					: auditData.length === (null || undefined)
					? setError("Error loading audits")
					: setAudits(auditData);

				return auditData;
			}
			loadAudits(audits);
		} catch (error) {
			setError("Error loading audits");
		}
	};

	const [timeOut, setTimeOut] = useState(3000);
	useEffect(() => {
		setIsLoading(true);
		(async () => {
			await getLogs();
		})();
	}, [pagination.role, pagination.page]);

	useEffect(() => {
		if (timeOut === 0) {
			getLogs();
		}
	}, [timeOut]);

 useEffect(_=>{
  Role.getRoles(pagination).then(res=>{
   setRole(res?.data?.roles?.map(({name})=> ({name, value: name})))
  })
 }, [])

	const handleTimeOut = () => {
		setTimeOut((state) => (state - 50 <= 0 ? 0 : state - 50));
	};

	if (pagination.search) setInterval(handleTimeOut, 100);
	else clearInterval(handleTimeOut);

	const handleSearch = async (e) => {
		setPagination((state) => ({ ...state, page: 1, search: value }));
		setIsLoading(true);
		setTimeOut((state) => 3000);
		const { value } = e.target;
		if (value) {
			setPagination((state) => {
				return { ...state, search: value };
			});
		} else {
			setPagination((state) => ({ ...state, search: "" }));
			getLogs();
		}
	};

 function handleFilter({ target: { value, name } }) {
		setPagination((state) => ({ ...state, [name]: value, page: 1}));
	}

	return (
		<HomeLayout>
			<div className="mt-[10%]">
				<div className="flex justify-between">
					<h1 className="font-[500] mb-14 text-[24px] text-[#263238]">
						Audits
					</h1>

					<div className="flex justify-end px-5 w-full">
      <p className="mt-5" style={{width: '90px'}}>Sort by</p>
      <Select
						name={"role"}
      height="40px"
						selectClass={"py-2 bg-white shadow block rounded-lg"}
						onChange={handleFilter}
						options={role}
					/>
					</div>

				</div>
				<div className="text-start ">
					<div className="table-one rounded-t-[10px] w-[1108px">
						<div className="  bg-[#F9F9F9] flex justify-between  py-4 px-7 ">
							<h1 className=" font-[400] text-[14px] text-[#010918] ">
								Audits
							</h1>
						</div>

						{audits.length ? (
							<Table
								{...{
									getLogs,
									data: audits,
									showModal,
									setModal,
									isLoading,
									pagination,
									setPagination,
								}}
							/>
						) : (
							<h1 style={{ color: "var(--C_gray)" }} className="text-center">
								{error}
							</h1>
						)}
					</div>
				</div>
			</div>
		</HomeLayout>
	);
};

export default Log;
