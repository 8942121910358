/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Input from '../form/inputOne';

const SignIn = ({ disabled, email, setError, setEmail, error, setMsg, setScreen }) => {

const [state, setState] = React.useState({pass:'',	error:''});
// regular expression to check if password has uppercase
const test1 = /^(?=.*[A-Z])/;
// regular expression to check if password has number
const test2 = /^(?=.*[0-9])/;
// regular expression to check if password has special character
const test3 = /([@$!%*?._|&:=;+,])/;
// regular expression to check if password has lowercase
const test4 = /^(?=.*[a-z])/;

const validatePassword = (e) => {
  const value = e.target.value;
  setState(state=>({...state, pass: value}));

  const result = [
    test1.test(value),
    test2.test(value),
    test3.test(value),
    test4.test(value),
  ].filter(Boolean);

   if (value.length < 8 || result.length < 4) {
    
      setState((state) => ({
      ...state,
      error:"Password must contain 8 characters, one uppercase, one lowercase, one number and one special character.",
        }));
  } else {
    setState((state) => ({
      ...state,
      error:"",
        }));
  }
};

    return (
            
            <div className="App w-[500px]">
            <p className="py-6 font-bold text-start text-[26px] text-[#525252]">
              Login 
            </p>
            {
						state.error && <span style={{color: 'var(--C_primary)', textAlign: 'left', display: 'block', lineHeight: 1.2}} className="mb-3">{state.error}</span>
					}
     
              <Input
                name={"email"}
                placeholder={" "}
                value={email}
                onChange={(e) => {setError(""); setEmail(e.target.value)}}
                type={"email"}
                label='Email Address'
                inpClass={`${error?'error':''} my-0 bg-white`}
                inputClass='px-2'
                labelClass='text-lg'
              />

              <Input
              inputClass='px-2'
                name={"password"}
                placeholder={" "}
                onChange={(e) => {
                  setError("")
                  validatePassword(e)
                }}
                type={"password"}
                label='Password'
                labelClass='text-lg'
                
                wrapperClass={'mt-2'}
                inpClass={`bg-white ${error?'error':''} my-0`}
              />

              <div className="text-start my-3">
                <span className="font-[400] text-[#263238]">
                Forgot Password? click 
                <a
                  className=" inline-block align-baseline font-[700]  hover:text-blue-800 mx-2"
                  href="#forgotPassword"
                  onClick={(e) => setScreen("forgot-password")}
                >
                  Here 
                </a>
                 to Reset
                </span>
              </div>
              <div className="flex items-center justify-between w-full">
                <button
                  className="login-custom-btn w-full mt-8"
                  type="submit"
                  disabled={disabled || state.error}
                  value={disabled && state.error ? "Loading..." : "Login"}
                >
                  Login
                </button>
              </div>
             </div>
    );
}

export default SignIn;