/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Modal from "../../layout/Modal";
import "./ModalOne.css";
import Imask from "../../assets/images/mask.svg";
import UserActions from "../../actions/user";
import Button from "../form/button/Button";

export default function CardRequest({ showModal, setModal, getUsers, data: {data, reject} }) {
	const [loading, setLoading] = useState({decline: false, approve: false})
	const [reason, setReason] = useState('default')

	const handleSubmit = (name, payload) => {
		if(name === 'approveCardRequest') setLoading(state => ({...state, approve: true}))
		else setLoading(state => ({...state, decline: true}))
		if(name === 'approveCardRequest') {
			UserActions[name](data?.id)
				.then(async () => {
					// closes the modal
					setModal(false);
					setLoading({approve: false, decline: false})
					// refreshes the users table
					await getUsers();
				})
				.catch((err) => {
					// setModal(false);
					setLoading({approve: false, decline: false})
				});
		} else {
			UserActions[name]({ reason }, data?.id)
			.then(async () => {
				// closes the modal
				setModal(state =>({...state, cardRequest: false}));
				setLoading({approve: false, decline: false})
				// refreshes the users table
				await getUsers();
			})
			.catch((err) => {
				setLoading({approve: false, decline: false})
			});
		}
	};
	const handleClose = () => {
		setModal(false);
	};
	return (
		<Modal {...{ showModal, setModal, width: "600px", height: "750px" }}>
				<form
					className="modal__one">
					<div>
						<div className="modal__one__header flex justify-between">
							<h1 className="text-start  py-0 mt-4 ml-5 font-[500] text-[16px] text-[#000000]">
        					View Card Request ({data?.accountType})
							</h1>
							<img
								src={Imask}
								alt=""
								className="py-0 mt-5 mr-5 cursor-pointer"
								onClick={handleClose}
							/>
						</div>
					</div>
					<hr className="border-t-1 border-[#E4E4EB] w-[525px] my-5 m-auto" />

					<div className="flex justify-between mb-5">
						<p  className="text-[gray]">Account Name</p>
						<p className="capitalize">{data?.accountName || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">BVN</p>
						<p>{data?.bvn || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Account Number</p>
						<p>{data?.accountNumber || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Card Type</p>
						<p>{data?.cardType || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Account Type</p>
						<p>{data?.accountType || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Mandate Type</p>
						<p>{data?.mandateType || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Preferred Name On Card</p>
						<p>{data?.nameOnCard || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Pickup Branch</p>
						<p>{data?.pickupBranch || 'Nil'}</p>
					</div>

					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Date of Request</p>
						<p>{data?.createdAt || 'Nil'}</p>
					</div>

					{	(data?.status) &&
						<div className="flex justify-between mb-5">
						<p className="text-[gray]">Status</p>
						<p>{data?.status.toUpperCase() || 'Nil'}</p>
						</div>
					}

					{	(data?.reason) &&
						<div className="flex justify-between mb-5">
						<p className="text-[gray]">Reason for Rejection</p>
						<p>{data?.reason || 'Nil'}</p>
						</div>
					}

					<div className="text-start -p-2">
						<img src={data?.signature} width="250px" height="250px" alt='Signature' />
					</div>

					{(reason === 'default') &&
					<>
					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Agent Email</p>
						<p>{data?.inputterName || 'Nil'}</p>
					</div>
					<div className="flex justify-between mb-5">
						<p className="text-[gray]">Sol ID</p>
						<p>{data?.inputterSol || 'Nil'}</p>
					</div>
					</>}

					{(reason !== 'default') &&
					<div className="-mt-4">
						<div className="flex justify-center my-9">
						<input className="flex mr-2 p-2 outline-none border border-2 rounded w-80" type='text' placeholder="Please give reason" onChange={e => setReason(e.target.value)} required />
						</div>
						<div className="flex justify-center my-5">
							<input className="border border-2 p-2 mr-2 cursor-pointer bg-gray-200 hover:bg-gray-500 hover:text-white w-32 rounded" type='button' value='Cancel' onClick={e => setReason('default')} />
							<input disabled={reason === ''} className="border border-2 p-2 mr-2 cursor-pointer disabled:bg-gray-500 bg-black text-white w-40 rounded" type='button' value={loading.decline ? "Processing..." : "Reject Request"} onClick={e => handleSubmit('rejectCardRequest')} />
						</div>
					</div>}
					{ (data?.status === 'pending' && reason === 'default') && 
					<div className="flex justify-center my-8">
						<Button disabled={loading.decline} {...{ 	width: "160px", value: loading.decline ? "Processing..." : "Reject Request", variant: 'dark', wrapperClass: 'mr-5',  onClick: _ => setReason('')}} />
						<Button disabled={loading.approve} {...{ 	width: "160px", value: loading.approve ? "Processing..." :  "Approve Request", wrapperClass: "py-0", onClick: _ => handleSubmit('approveCardRequest') }} />
					</div>}
				</form>
			
		</Modal>
	);
}
