/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Input from "../form/inputOne";

const ChangePassword = ({ email,setScreen, setError, error}) => {

	const [state, setState] = useState({pass:'',	error:''});
	// regular expression to check if password has uppercase
	const test1 = /^(?=.*[A-Z])/;
	// regular expression to check if password has number
	const test2 = /^(?=.*[0-9])/;
	// regular expression to check if password has special character
	const test3 = /([@$!%*?._|&:=;+,])/;
	// regular expression to check if password has lowercase
	const test4 = /^(?=.*[a-z])/;

	const validatePassword = (e) => {
		const value = e.target.value;
		setState(state=>({...state, pass: value}));	

		const result = [
			test1.test(value),
			test2.test(value),
			test3.test(value),
			test4.test(value),
		].filter(Boolean);

		 if (value.length < 8 || result.length < 4) {
			
				setState((state) => ({
				...state,
				error:"Password must contain 8 characters, one uppercase, one lowercase, one number and one special character.",
					}));
		} else {
			setState((state) => ({
				...state,
				error:"",
					}));
		}
	};


  return (
          <div
            className="login-form w-[500px]"
          >
            <div className="flex justify-center ">
              <div className="w-full ">
                <p className="text-[#525252] font-bold text-[25px] text-start mb-5"> Update Password </p>
					{
						state.error && <span style={{color: 'var(--C_primary)', textAlign: 'left', display: 'block', lineHeight: 1.2}} className="mb-3">{state.error}</span>
					}
                
                <Input
                  name={"email"}
                  value={email}
																		placeholder=" "
                  type={"email"}
                  required={true}
																		inputClass='px-2'
              				labelClass='text-lg'
																		inpClass='my-0'
																		label='Email Address'
                />

                <Input
                  name={"newpassword"}
                  placeholder={" "}
                  type={"password"}
                  required={true}
              				labelClass='text-lg'
																		inpClass='my-0 bg-white'
																		label='Password'
																		onChange={validatePassword}
                  wrapperClass={"mt-5"}
                />

                <div className="flex items-center justify-center">
                  <button
                  className="login-custom-btn w-full mt-8" 
                  value={"Change Password"}
                  type="submit"
																		disabled={state.error}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
  );
 }

export default ChangePassword;
	