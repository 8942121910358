/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useState } from "react";
import Alert from "../utils/alert";
import config from "../utils/config";

const context = createContext({});
const { pageUrls } = config;
export const useHomeContext = () => useContext(context);

const Home = ({ children }) => {
	const eta = 180;
	const [timer, setTimer] = useState(eta);

	const resetTimer = useCallback(
		(_) => {
			if (window.location.pathname !== pageUrls.login) {
				setTimer((state) => {
					if ( state > 0 && state <= 10)
						Alert({
							type: "success",
							message: "Interaction detected: User is now active.",
						});
					return eta;
				});
			}
		},
		[timer],
	);

	const value = {
  // logout,
  resetTimer,
  timer,
  setTimer
 };
	return <context.Provider {...{ value }}>{children}</context.Provider>;
};

export default Home;
