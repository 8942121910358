import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'

const Wallet = () => {
  return (
    
    <div>
        <Sidebar />
        <h1 className="text-center">Wallet page still under development</h1>

    </div>
  )
}

export default Wallet