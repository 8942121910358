/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
// import { getRole, Roles, Statuses } from "../../../utils/helpers";
import styled from "styled-components";
import HomeLayout from "../../layout/Home";
import Avatar from "../../assets/images/avatar.png";
import JsPDF from 'jspdf';
import { LazyLoadImage } from "react-lazy-load-image-component";


const Info = styled.p`
	border-bottom: 1px solid black;
	padding-bottom: 5px;
	background-color: transparent;
	width: 250px;
	text-align: center;
`;
// const { REACT_APP_PUBLIC_URL } = process.env;
const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL ?? 'https://uinstaprodapi.ubagroup.com';

export default function Profile() {
	const { state } = useLocation();
	const data = useMemo(() => {
		let {
			__data: { text },
		} = state;
		let data = { ...state.__data, text: {} };
		text?.map((text) => {
			data.text[text.label?.toLowerCase().replaceAll(" ", "_")] = text.value;
		});
		return data;
	}, [state]);

	const generatePDF = () => {

		const report = new JsPDF('landscape','pt');
		report.html(document.querySelector('#report')).then(() => {
			report.save('uinsta_one_report.pdf');
		});
	}

	const mappedPortraitSignature = data.portraitSignature.map(x => {
		if (x.resourceKey) {
			let splitX = x?.resourceKey?.split('/');
			if (splitX[splitX.length - 1].indexOf('DROID') < 0) {
				let DROID = splitX[splitX?.length - 2];
				let clonedFormattedUrl = x.formattedUrl;
				let splitFormattedUrl = clonedFormattedUrl.split('_');
				splitFormattedUrl.splice(1, 1, DROID);
				x.formattedUrl = splitFormattedUrl.join('_');
			}
		}
		return x.formattedUrl;
	});
	const mappedFileUpload = data.fileUpload.map(x => {
		if (x.resourceKey) {
			let splitX = x?.resourceKey?.split('/');
			if (splitX[splitX.length - 1].indexOf('DROID') < 0) {
				let DROID = splitX[splitX?.length - 2];
				let clonedFormattedUrl = x.formattedUrl;
				let splitFormattedUrl = clonedFormattedUrl.split('_');
				splitFormattedUrl.splice(1, 1, DROID);
				x.formattedUrl = splitFormattedUrl.join('_');
			}
		}
		return x.formattedUrl;
	});

	const portrait = data.portraitSignature.filter(x => x?.formattedUrl);
	const files = data.fileUpload.filter(x => x?.formattedUrl);
	const portraitImg = portrait.length && portrait.filter(img => img.label?.toLowerCase().indexOf('signature') < 0)[0]?.formattedUrl
	const signatureImg = portrait.length && portrait.filter(img => img.label?.toLowerCase().indexOf('signature') >= 0)[0]?.formattedUrl
	const identificationImg = files.length && files.filter(img => img.label?.toLowerCase().indexOf('identification') >= 0)[0]?.formattedUrl
	const mandateImg = files.length && files.filter(img => img.label?.toLowerCase().indexOf('mandate') >= 0)[0]?.formattedUrl
	const utilityImg = files.length && files.filter(img => img.label?.toLowerCase().indexOf('utility') >= 0)[0]?.formattedUrl
	const accountOpeningImg = files.length && files.filter(img => img.label?.toLowerCase().indexOf('opening') >= 0)[0]?.formattedUrl
	

	return (
		<HomeLayout {...{ showNavigation: true }}>
			<button className="mt-10 bg-gray-300 rounded p-1 cursor-pointer hover:bg-white" onClick={generatePDF} type="button">Download as PDF</button>
			<div id="report" className="mt-[1%] border-2 px-5 rounded shadow mb-5">
			{/* <div className="text-bold mt-5 mb-3 m-auto w-full" style={{ color: "var(--C_black)", fontWeight: "bold" }}> */}
				<h2><b><span className="mr-2">UNITED</span><span className="mr-2">BANK</span><span className="mr-2">OF</span><span className="mr-2">AFRICA</span><span>PLC</span></b></h2>
				<span className="mr-2">CUSTOMER</span> <span>INFORMATION</span>
			{/* </div> */}
				<form className="w-full md:w-4/5 md:mx-right-auto my-10">
					<div className="flex mt-5 w-full justify-between items-center">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								First Name
							</p>
							<Info>{data.text.first_name}</Info>
						</div>

						<div className="mr-5">
							<p
								className="text-bold mb-1 "
								style={{ color: "var(--C_black)" }}>
								Middle Name
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{" "}
								{data.text.middle_name}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Last Name
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.last_name}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Email Address
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.email}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Phone Number
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.phone_number}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								State Of Origin
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.state_of_origin || "Nil"}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Scheme Description
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.scheme_description}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								BVN
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.bvn}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								RMID
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.rmid || "Nil"}{" "}
							</Info>
						</div>
					</div>

					<div className="flex items-center justify-between mt-12">
						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Date Of Birth
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.date_of_birth}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Gender
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.gender}{" "}
							</Info>
						</div>

						<div className="mr-5">
							<p className="text-bold mb-1" style={{ color: "var(--C_black)" }}>
								Branch Address
							</p>
							<Info className="text- text-sm mb-2 mt-3 text-start">
								{data.text.branch_address || "Nil"}{" "}
							</Info>
						</div>
					</div>
				</form>

				<div className="flex mt-20 ml-10">
				{data.portraitSignature.filter((a) => a.label?.toLowerCase().indexOf('passport') !== -1).map((img, idx) => (
						<div key={idx} className="mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="Passport"/>
						</div>
				))}
				{data.portraitSignature.filter((a) => a.label?.toLowerCase().indexOf('signature') !== -1).map((img, idx) => (
						<div key={idx} className="mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="Signature"/>
						
						</div>
				))}
				</div>
				<div className="flex mt-20 mb-20 ml-10">
				{data.fileUpload.filter((a) => (a.label?.toLowerCase().indexOf('identification') >= 0)).map((img, idx) => (
						<div key={idx} className="mt-8 mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="Identification"/>
						</div>
				))}
				</div>
				<div className="flex mt-20 mb-20 ml-10">
				{data.fileUpload.filter((a) => a.label?.toLowerCase().indexOf('mandate') >= 0).map((img, idx) => (
						<div key={idx} className="mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="Mandate"/>
						</div>
				))}
				{data.fileUpload.filter((a) => a.label?.toLowerCase().indexOf('utility') >= 0).map((img, idx) => {
						return <div key={idx} className="mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar} src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="Utility"/>
						</div>
})}
				{data.fileUpload.filter((a) => a.label?.toLowerCase().indexOf('opening') >= 0).map((img, idx) => (
						<div key={idx} className="mx-5">
						<p className="text-left ml-1 mb-2">{img.label}</p>
						<LazyLoadImage placeholderSrc={Avatar}  src={`${REACT_APP_PUBLIC_URL}/seed/uinstafiles/${img?.formattedUrl}`} width="150px" height="150px" alt="A/C Opening"/>
						</div>
				))}
				</div>
			
			</div>
		</HomeLayout>
	);
}
