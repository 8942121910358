import React, { useState } from "react";
import './InputOne.css';
import { IoMdEyeOff, IoMdEye } from "react-icons/io"
const Input = ({
  id,
  label,
  icon,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  onClick,
  onFocus,
  iconClass,
  type,
  inputClass,
  wrapperClass,
  disabled,
  maxLength,
  labelClass,
  required,
  children,
  inpClass
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`text-start ${wrapperClass}`}>
      <label className={`block text-sm mb-1 ${labelClass}`} htmlFor={id||name}>
        {label}
      </label>
      <div className={`flex select_role  y-0 px- my-5  border border-[#D9D8E3] font-[400] text-[14px] text-[#000000] ${inpClass}`}>
        <input
          className={ `w-full py-4  text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${inputClass}`}
          type={(type || "text") && showPassword ? "text" : type}
          name={name}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus} 
          id={id || name}
          placeholder={placeholder || name}
          onChange={ onChange }
          disabled={disabled}
          maxLength={maxLength}
          onClick={onClick}
          required={required}
        />
        {/* {icon && <img src={icon} className={iconClass} />} */}
        {icon ? (
					<img src={icon} className={iconClass} alt='icon'/>
				) : type === "password" ? (
					showPassword ? (
						<IoMdEye
							onClick={(e) => setShowPassword(!showPassword)}
							className="icon pr-2 mt-4 cursor-pointer"
							size="2em"
						/>
					) : (
						<IoMdEyeOff
							onClick={(e) => setShowPassword(!showPassword)}
							className="icon pr-2 mt-4 cursor-pointer"
							size="2em"
						/>
					)
				) : null}
        {children}
      </div>
    </div>
  );
};
export default Input;
